import React, { useRef, useState } from 'react';
import { Search, ExternalLink, Ruler, WebhookIcon, StickyNote, DollarSign, Check, X, Edit2, Trash2, SearchX, Lock, ChevronUp, ChevronDown, Filter } from 'lucide-react';
import type { MonitorInput } from '../../../../types';
import { createPortal } from 'react-dom';


interface FilterOption {
    name: string;
    icon: React.ReactNode;
    developerOnly?: boolean;
}

interface InputListProps {
    items: MonitorInput[];
    availableRegions: string[];
    toggleActive: (index: number) => void;
    openEditDialog: (item: MonitorInput) => void;
    removeItem: (index: number) => void;
    handleBlur: () => void;
    searchTerm: string;
    selectedFilter: string;
    setSearchTerm: (term: string) => void;
    setSelectedFilter: (term: string) => void;
    filterOptions: FilterOption[]
}

const InputList: React.FC<InputListProps> = ({
    items,
    availableRegions,
    toggleActive,
    openEditDialog,
    removeItem,
    searchTerm,
    selectedFilter,
    setSearchTerm,
    handleBlur,
    filterOptions,
    setSelectedFilter
}) => {
    const [tooltip, setTooltip] = useState<{ content: string; x: number; y: number } | null>(null);
    const [filterOpen, setFilterOpen] = useState<boolean>(false);

    const tooltipRef = useRef<HTMLDivElement>(null);
    const filterButtonRef = useRef<HTMLButtonElement>(null);

    const handleFilterChange = (filter: string) => {
        setSelectedFilter(filter);
        setFilterOpen(false);
    };
    const getFilterIcon = (filterName: string) => {
        const option = filterOptions.find(opt => opt.name === filterName);
        return option?.icon || <Filter size={16} className="text-slate-400" />;
    };



    const renderLinkOrText = (link: string) => {
        if (link.toLowerCase().includes('http')) {
            return (
                <div className="flex items-center gap-2">
                    <ExternalLink size={16} className="text-blue-400 flex-shrink-0" />
                    <a
                        href={link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-400 hover:text-blue-300 transition-colors"
                    >
                        {link}
                    </a>
                </div>
            );
        }
        return <span className="text-slate-100">{link}</span>;
    };

    const handleTooltip = (e: React.MouseEvent<Element>, content: string) => {
        const rect = e.currentTarget.getBoundingClientRect();
        const buttonCenter = rect.left + rect.width / 2;
        setTooltip({
            content,
            x: buttonCenter + window.scrollX,
            y: rect.top + window.scrollY - 5
        });
    };

    return (
        <div className="flex flex-col bg-[#1a2234] rounded-xl ">
            {/* New Search Option */}
            <div className="border-slate-700/50 pb-1">
                <div className="flex flex-col sm:flex-row gap-4">
                    <div className="relative">
                        <button
                            ref={filterButtonRef}
                            onClick={() => setFilterOpen(!filterOpen)}
                            style={{
                                minWidth: "200px"
                            }}
                            className="flex items-center gap-2 px-3 py-2 bg-[#0f1729] text-slate-300 rounded-lg text-sm border border-slate-700/50 hover:border-slate-600/50 transition-colors w-full sm:w-auto"
                        >
                            {getFilterIcon(selectedFilter)}
                            <span className="flex-1 text-left">{selectedFilter}</span>
                            {filterOpen ?
                                <ChevronUp size={16} className="text-slate-400" /> :
                                <ChevronDown size={16} className="text-slate-400" />
                            }
                        </button>

                        {filterOpen && createPortal(
                            <div
                                className="fixed bg-[#0f1729] rounded-lg border border-slate-700/50 shadow-xl py-1 z-50"
                                style={{
                                    bottom: window.innerHeight - (filterButtonRef.current ? filterButtonRef.current.getBoundingClientRect().top : 0) + 8,
                                    left: filterButtonRef.current ? filterButtonRef.current.getBoundingClientRect().left : 0,
                                    width: filterButtonRef.current ? filterButtonRef.current.getBoundingClientRect().width : 0,
                                    minWidth: "200px"
                                }}
                            >
                                <div className="fixed inset-0 -z-10" onClick={() => setFilterOpen(false)} />
                                {filterOptions.map((option) => (

                                    <button
                                        key={option.name}
                                        onClick={() => handleFilterChange(option.name)}
                                        className="w-full px-3 py-2 text-sm text-slate-300 hover:bg-slate-700/50 text-left transition-colors flex items-center gap-2"
                                    >
                                        {option.icon}
                                        <span>{option.name}</span>
                                    </button>

                                ))}
                            </div>,
                            document.body
                        )}
                    </div>

                    <div className="relative flex-1 sm:max-w-xs">
                        <input
                            type="text"
                            autoFocus
                            placeholder="Search inputs..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="w-full bg-[#0f1729] rounded-lg pl-9 pr-4 py-2 text-sm text-slate-100 focus:outline-none focus:ring-1 focus:ring-blue-500 border border-slate-700/50"
                        />
                        <Search
                            size={16}
                            className="absolute left-3 top-1/2 -translate-y-1/2 text-slate-400"
                        />
                    </div>
                    <div className="flex items-center justify-center min-w-[80px] text-sm text-slate-400">
                        {items.length} input{items.length !== 1 ? 's' : ''}
                    </div>
                </div>
            </div>

            {/* Table Container - Scrollable */}
            <div className="min-h-0 overflow-auto  pb-4" style={{
                maxHeight: items.length > 0 ? 'calc(100vh - 200px)' : 'auto'
            }}>
                <table className="w-full text-left border-collapse">
                    <thead className="sticky top-0 bg-[#1a2234] z-10">
                        <tr className="border-b border-slate-700/50">
                            <th className="py-3 px-4 text-sm font-medium text-slate-400">Link/Product ID</th>
                            {availableRegions.length > 1 && (
                                <th className="py-3 px-4 text-sm font-medium text-slate-400 hidden sm:table-cell">Region</th>
                            )}
                            <th className="py-3 px-4 text-sm font-medium text-slate-400 text-center">Status</th>
                            <th className="py-3 px-4 text-sm font-medium text-slate-400 text-center">Actions</th>
                        </tr>
                    </thead>
                    <tbody className="relative">
                        {items.length > 0 ? (
                            items.map((item, index) => (
                                <tr
                                    key={item.id || index}
                                    className="border-b border-slate-700/50 last:border-b-0 hover:bg-slate-700/20 transition-colors"
                                >
                                    <td className="py-3 px-4">
                                        <div className="flex flex-wrap items-center gap-2">
                                            {renderLinkOrText(item.link)}
                                            <div className="flex items-center gap-1.5">
                                                {item.sizeRange !== '36-50' && (
                                                    <Ruler
                                                        size={16}
                                                        className="text-yellow-400 cursor-help"
                                                        onMouseEnter={(e) => handleTooltip(e, `Size Range: ${item.sizeRange}`)}
                                                        onMouseLeave={() => setTooltip(null)}
                                                    />
                                                )}
                                                {item.specialWebhook && (
                                                    <WebhookIcon
                                                        size={16}
                                                        className="text-purple-400 cursor-help"
                                                        onMouseEnter={(e) => handleTooltip(e, `Special Webhook`)}
                                                        onMouseLeave={() => setTooltip(null)}
                                                    />
                                                )}
                                                {item.note && (
                                                    <StickyNote
                                                        size={16}
                                                        className="text-emerald-400 cursor-help"
                                                        onMouseEnter={(e) => handleTooltip(e, `Note: ${item.note}`)}
                                                        onMouseLeave={() => setTooltip(null)}
                                                    />
                                                )}
                                                {item.priceLimit !== undefined && item.priceLimit !== null && (
                                                    <DollarSign
                                                        size={16}
                                                        className="text-orange-400 cursor-help"
                                                        onMouseEnter={(e) => handleTooltip(e, `Price Limit: ${item.priceLimit}`)}
                                                        onMouseLeave={() => setTooltip(null)}
                                                    />
                                                )}
                                                {item.private && (
                                                    <Lock
                                                        size={16}
                                                        className="text-indigo-400 cursor-help"
                                                        onMouseEnter={(e) => handleTooltip(e, 'Private Input')}
                                                        onMouseLeave={() => setTooltip(null)}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </td>
                                    {availableRegions.length > 1 && (
                                        <td className="py-3 px-4 hidden sm:table-cell">
                                            <span className="text-slate-300">{item.region || availableRegions[0]}</span>
                                        </td>
                                    )}
                                    <td className="py-3 px-4">
                                        <div className="flex justify-center">
                                            <span className={`px-2 py-1 text-xs rounded-full ${item.active
                                                ? 'bg-emerald-500/10 text-emerald-400 border border-emerald-500/20'
                                                : 'bg-red-500/10 text-red-400 border border-red-500/20'
                                                }`}>
                                                {item.active ? 'Active' : 'Inactive'}
                                            </span>
                                        </div>
                                    </td>
                                    <td className="py-3 px-4">
                                        <div className="flex items-center justify-center gap-2">
                                            <button
                                                onClick={() => toggleActive(index)}
                                                className="p-2 hover:bg-slate-700/50 rounded-lg transition-colors group"
                                            >
                                                {item.active ? (
                                                    <X size={16} className="text-slate-400 group-hover:text-red-400" />
                                                ) : (
                                                    <Check size={16} className="text-slate-400 group-hover:text-emerald-400" />
                                                )}
                                            </button>
                                            <button
                                                onClick={() => { openEditDialog(item); handleBlur(); }}
                                                className="p-2 hover:bg-slate-700/50 rounded-lg transition-colors group"
                                            >
                                                <Edit2 size={16} className="text-slate-400 group-hover:text-yellow-400" />
                                            </button>
                                            <button
                                                onClick={() => removeItem(index)}
                                                className="p-2 hover:bg-slate-700/50 rounded-lg transition-colors group"
                                            >
                                                <Trash2 size={16} className="text-slate-400 group-hover:text-red-400" />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={availableRegions.length > 1 ? 4 : 3}>
                                    <div className="py-8">
                                        <div className="flex flex-col items-center gap-4 text-center">
                                            <SearchX size={32} className="text-slate-400" />
                                            <div className="space-y-1">
                                                <h3 className="font-medium text-slate-300">No inputs found</h3>
                                                <p className="text-sm text-slate-400">
                                                    Try adjusting your search or add a new input.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            {/* Tooltip */}
            {tooltip && (
                <div
                    ref={tooltipRef}
                    className="fixed z-50 px-3 py-1.5 bg-[#1a2234] text-sm text-slate-100 rounded-lg shadow-xl border border-slate-700/50"
                    style={{
                        left: `${tooltip.x}px`,
                        top: `${tooltip.y}px`,
                        transform: 'translate(-50%, -100%)',
                        pointerEvents: 'none',
                    }}
                >
                    {tooltip.content}
                    <div
                        className="absolute left-1/2 bottom-0 w-2 h-2 bg-[#1a2234] border-r border-b border-slate-700/50 -translate-x-1/2 translate-y-1/2 rotate-45"
                    />
                </div>
            )}
        </div>
    );
};

export default InputList;