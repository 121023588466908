import React, { useEffect, useState } from 'react';
import { ResponsiveSidebar } from './Sidebar/Sidebar';
import { BotData, Customer, User } from '../../types';
import { CustomToastContainer } from '../common/CustomToastContainer';
import { classNames } from '../../utils/classNames';

interface DashboardLayoutProps {
    children: React.ReactNode;
    user: User;
    botData: BotData;
    customerData: Customer;
    onLogout: () => void;
    isBlurred: boolean;
    handleBlur: () => void;
    developer: boolean;
}

export const DashboardLayout: React.FC<DashboardLayoutProps> = ({
    children,
    user,
    botData,
    customerData,
    onLogout,
    isBlurred,
    developer,
    handleBlur
}) => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return (
        <div className={`flex h-screen overflow-hidden bg-gray-100 dark:bg-gray-900 ${isBlurred ? 'backdrop-blur-sm' : ''}`}>
            <CustomToastContainer limit={1} />
            <ResponsiveSidebar
                user={user}
                botData={botData}
                purchases={customerData.purchases}
                onLogout={onLogout} developer={developer} />
            <main className={classNames(
                "flex-1 overflow-auto w-full",
                isMobile && "pt-16"
            )}>
                {children}
            </main>
        </div>
    );
};