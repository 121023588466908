import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import KeywordSettings from './KeywordSettings';
import ProxySettings from './ProxySettings';

const DeveloperSettings = ({ developer, handleBlur }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="rounded-lg mb-6">
            <button
                onClick={() => setIsOpen(!isOpen)}
                className="w-full px-6 py-4 flex items-center justify-between text-white hover:bg-[#232838] rounded-lg transition-colors"
            >
                <span className="text-lg font-medium">Developer Settings</span>
                {isOpen ? (
                    <ChevronUp className="h-5 w-5 text-gray-400" />
                ) : (
                    <ChevronDown className="h-5 w-5 text-gray-400" />
                )}
            </button>

            {isOpen && developer && (
                <div className="p-6 border-t border-[#2a2f3d]">
                    <div className="flex flex-col md:flex-row md:space-x-4">
                        <div className="w-full md:w-1/2">
                            <ProxySettings handleBlur={handleBlur} />
                        </div>
                        <div className="w-full md:w-1/2">
                            <KeywordSettings handleBlur={handleBlur} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DeveloperSettings;