import React, { useState, useEffect, useRef } from 'react';
import { Monitor, MonitorInput, Customer } from '../../../../types';
import { Plus, X, HelpCircle, Globe, CheckCircle, Check, Ban, Lock } from 'lucide-react';
import ExampleInputs from './ExampleInputs';
import InputList from './InputList';
import EditDialog from './EditDialog';
import InputsNotAllowedMessage from './InputsNotAllowedMessage';
import RegionSelect from './RegionSelect';
import OptionalInputs from './OptionalInputs';
import { API_URL } from '../../../../constants';
import axios, { AxiosError, AxiosResponse } from 'axios';

interface MonitoredLinksSectionProps {
    currentMonitor: Monitor;
    setCurrentMonitor: (monitor: Monitor) => void;
    tempMonitor: Monitor | null;
    setTempMonitor: (monitor: Monitor) => void;
    showToast: (message: string, type: "info" | "success" | "error") => void;
    developer: boolean;
    customerData: Customer | null
    handleBlur: () => void;
}
interface FilterOption {
    name: string;
    icon: React.ReactNode;
    developerOnly?: boolean;
}

const MonitoredLinksSection: React.FC<MonitoredLinksSectionProps> = ({
    currentMonitor,
    setCurrentMonitor,
    tempMonitor,
    setTempMonitor,
    showToast,
    developer,
    customerData,
    handleBlur,
}) => {
    const [items, setItems] = useState<MonitorInput[]>(currentMonitor.inputs || []);
    const [newItem, setNewItem] = useState<MonitorInput>({
        link: '',
        sizeRange: '36-50',
        specialWebhook: '',
        region: "all",
        note: '',
        priceLimit: undefined,
        active: true,
        id: -1
    });
    const [showOptionalInputs, setShowOptionalInputs] = useState(false);
    const [showExamples, setShowExamples] = useState(false);
    const [inputAmount, setInputAmount] = useState(currentMonitor.inputs.length);
    const [editingItem, setEditingItem] = useState<MonitorInput | null>(null);
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [changedItems, setChangedItems] = useState<MonitorInput[]>([]);
    const [filteredItems, setFilteredItems] = useState<MonitorInput[]>(currentMonitor.inputs || []);
    const [newItems, setNewItems] = useState<MonitorInput[]>([]);
    const [tooltip, setTooltip] = useState<{ content: string; x: number; y: number } | null>(null);
    const tooltipRef = useRef<HTMLDivElement>(null);
    const [selectedFilter, setSelectedFilter] = useState<string>("All");

    const filterOptions: FilterOption[] = [
        { name: 'All', icon: <Check size={16} className="text-gray-400" /> },
        { name: 'Active', icon: <CheckCircle size={16} className="text-emerald-400" /> },
        { name: 'Disabled', icon: <Ban size={16} className="text-red-400" /> },
        ...(customerData?.purchases.monitor.includes("private inputs") || customerData?.purchases.monitor.includes("Alternative") ?
            [{ name: 'Private', icon: <Lock size={16} className="text-indigo-400" /> }]
            : []),
        ...(currentMonitor.webhooks && currentMonitor.webhooks.length > 1
            ? Array.from(new Set(currentMonitor.webhooks.map(webhook => webhook.region.split('-')[0])))
                .map(region => ({
                    name: `Region ${region}`,
                    icon: <Globe size={16} className="text-blue-400" />
                }))
            : [])
    ];

    const showConfirmationPopup = (): Promise<boolean> => {
        return new Promise((resolve) => {
            const confirmed = window.confirm("The input has already been added to the monitor. Are you sure you want to add it again?");
            resolve(confirmed);
        });
    };

    const sendRequest = async (items: any[], method: 'post' | 'put', url: string, force: boolean = false): Promise<AxiosResponse> => {
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 30000); // 30 second timeout

        try {
            const response = await axios({
                method,
                url,
                data: {
                    inputs: items,
                    force
                },
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": localStorage.getItem("token") || "",
                },
                signal: controller.signal
            });
            return response;
        } catch (error) {
            if (axios.isCancel(error)) {
                throw new Error("Request timed out");
            }
            if (axios.isAxiosError(error)) {
                const axiosError = error as AxiosError;
                if (axiosError.response?.status === 409) {
                    const confirmed = await showConfirmationPopup();
                    if (confirmed) {
                        showToast("Input added successfully", "success");
                        return sendRequest(items, method, url, true);

                    }
                    throw new Error("User cancelled the operation");
                }
            }
            throw error;
        } finally {
            clearTimeout(timeoutId);
        }
    };

    const updateItemsWithNewIds = (updatedItems: MonitorInput[]) => {
        console.log("adding new product!")
        console.log(updatedItems)
        setItems(prevItems => {
            const newItems = [...prevItems];
            updatedItems.forEach(updatedItem => {
                const existingItemIndex = newItems.findIndex(item => item.id === -1 && item.link === updatedItem.link);
                if (existingItemIndex !== -1) {
                    // Update the ID of the existing item
                    newItems[existingItemIndex] = { ...newItems[existingItemIndex], id: updatedItem.id };
                } else {
                    // Append the new item
                    let newItem = {
                        "active": updatedItem.active,
                        "id": updatedItem.id,
                        "link": updatedItem.link,
                        "note": updatedItem.note,
                        "region": updatedItem.region,
                        "sizeRange": updatedItem.sizeRange,
                        "specialWebhook": updatedItem.specialWebhook,
                        "private": updatedItem.private,
                        "priceLimit": updatedItem.priceLimit
                    }

                    newItems.push(newItem);
                }
            });
            return newItems;
        });
        setNewItems([]);
        setChangedItems([]);
    };

    const addItem = async () => {
        if (!newItem.link?.trim()) return;

        try {
            showToast("Adding new input...", "info");
            const response = await sendRequest(
                [{ ...newItem, id: -1 }],
                'post',
                `${API_URL}/v1/monitors/${currentMonitor.data.monitor_uuid}/inputs/new`
            );

            const updatedNewItems = response.data.inputs;
            updateItemsWithNewIds(updatedNewItems);
            setNewItems(prevNewItems => [...prevNewItems, ...updatedNewItems]);
            showToast("Input added successfully", "success");

            // Reset form only after successful addition
            setNewItem({
                link: '',
                sizeRange: '36-50',
                specialWebhook: '',
                region: 'all',
                note: '',
                priceLimit: undefined,
                active: true,
                id: -1
            });
            setShowOptionalInputs(false);
        } catch (error) {
            console.error('Failed to add new item:', error);
            if (error instanceof Error && error.message !== "User cancelled the operation") {
                showToast(error.message === "Request timed out"
                    ? "Request timed out. Please try again."
                    : "Failed to add input", "error");
            }
        }
    };

    const addItemDisabled = async () => {
        if (newItem.link) {
            const newItemWithId = { ...newItem, id: -1, active: false };
            try {
                showToast("Adding new disabled input...", "info");
                const response = await sendRequest([newItemWithId], 'post', `${API_URL}/v1/monitors/${currentMonitor.data.monitor_uuid}/inputs/new`, true);
                const updatedNewItems = response.data.inputs;
                showToast("Input added successfully", "success");
                updateItemsWithNewIds(updatedNewItems);
                setNewItems(prevNewItems => [...prevNewItems, ...updatedNewItems]);
            } catch (error) {
                console.error('Failed to add new disabled item', error);
                showToast("Failed to add disabled input", "error");
            }
            setNewItem({
                link: '',
                sizeRange: '36-50',
                specialWebhook: '',
                region: 'all',
                note: '',
                priceLimit: undefined,
                active: true,
                id: -1
            });
            setShowOptionalInputs(false);
        }
    };

    const removeItem = async (index: number) => {
        const deletedItem = filteredItems[index];
        if (!deletedItem) return;

        const previousItems = [...items]; // Store for rollback

        try {
            // Optimistic update
            setItems(prevItems => prevItems.filter(item => item.id !== deletedItem.id));
            setNewItems(prevNewItems => prevNewItems.filter(item => item.id !== deletedItem.id));
            setChangedItems(prevChangedItems => prevChangedItems.filter(item => item.id !== deletedItem.id));

            if (deletedItem.id !== -1) {
                showToast("Deleting input...", "info");

                const response = await axios.delete(
                    `${API_URL}/v1/monitors/${currentMonitor.data.monitor_uuid}/inputs`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': localStorage.getItem('token') || '',
                        },
                        data: { item: deletedItem },
                        timeout: 30000
                    }
                );
                if (response.status === 200) {
                    showToast("Input deleted successfully", "success");
                }
            }
        } catch (error) {
            // Rollback on failure
            setItems(previousItems);

            console.error('Failed to delete item:', error);
            if (axios.isAxiosError(error)) {
                const errorMessage = error.response?.status === 401
                    ? "Session expired. Please log in again."
                    : "Error deleting input";
                showToast(errorMessage, "error");
            } else {
                showToast("Error deleting input", "error");
            }
        }
    };

    const toggleActive = (index: number) => {
        // Get the actual item from filteredItems
        const targetItem = filteredItems[index];

        // Find the original index in the full items array
        const originalIndex = items.findIndex(item => item.id === targetItem.id);

        setItems(prevItems => prevItems.map((item, i) => {
            if (i === originalIndex) {
                const updatedItem = { ...item, active: !item.active };

                // Handle new items (id === -1)
                if (item.id === -1) {
                    setNewItems(prevNewItems => prevNewItems.map(newItem =>
                        newItem.id === item.id ? updatedItem : newItem
                    ));
                }
                // Handle existing items
                else {
                    setChangedItems(prevChangedItems => {
                        const existingItemIndex = prevChangedItems.findIndex(
                            changedItem => changedItem.id === item.id
                        );

                        if (existingItemIndex !== -1) {
                            return prevChangedItems.map(changedItem =>
                                changedItem.id === item.id ? updatedItem : changedItem
                            );
                        } else {
                            return [...prevChangedItems, updatedItem];
                        }
                    });
                }
                return updatedItem;
            }
            return item;
        }));
    };

    const handleTooltipLeave = () => {
        setTooltip(null);
    };

    const updateItem = (updatedItem: MonitorInput) => {
        setItems(prevItems => prevItems.map(item =>
            item.id === updatedItem.id ? { ...updatedItem } : item
        ));
        if (updatedItem.id === -1) {
            setNewItems(prevNewItems => prevNewItems.map(item =>
                item.id === updatedItem.id ? { ...updatedItem } : item
            ));
        } else {
            setChangedItems(prevChangedItems => {
                const existingItemIndex = prevChangedItems.findIndex(item => item.id === updatedItem.id);
                if (existingItemIndex !== -1) {
                    return prevChangedItems.map(item =>
                        item.id === updatedItem.id ? { ...updatedItem } : item
                    );
                } else {
                    return [...prevChangedItems, updatedItem];
                }
            });
        }
        setIsEditDialogOpen(false);
    };

    const updateMonitorInputs = async () => {
        if (!currentMonitor || !tempMonitor || !changedItems.length) return;

        try {
            showToast("Updating inputs...", "info");

            const response = await sendRequest(
                changedItems,
                'put',
                `${API_URL}/v1/monitors/${currentMonitor.data.monitor_uuid}/inputs/update`
            );

            if (response.status === 200) {
                setCurrentMonitor({
                    ...currentMonitor,
                    inputs: items
                });
                setTempMonitor({
                    ...currentMonitor,
                    inputs: items
                });
                setNewItems([]);
                setChangedItems([]);

                showToast("Inputs updated successfully", "success");
            }
        } catch (error) {
            console.error('Failed to update monitor inputs:', error);
            const errorMessage = error instanceof Error && error.message === "Request timed out"
                ? "Update timed out. Please try again."
                : "Failed to update inputs";
            showToast(errorMessage, "error");
        }
    };

    useEffect(() => {
        if (changedItems.length > 0) {
            console.log('Inputs have changed, updating monitor inputs...');
            updateMonitorInputs();
        }
    }, [changedItems]);

    useEffect(() => {
        setInputAmount(items.length)
    }, [items]);

    const handleSizeRangeChange = (e: React.ChangeEvent<HTMLInputElement>, type: 'from' | 'to', item: MonitorInput | null = null) => {
        const [from, to] = (item ? item.sizeRange : newItem.sizeRange).split('-').map(Number);
        const value = parseInt(e.target.value);

        let newFrom = type === 'from' ? value : from;
        let newTo = type === 'to' ? value : to;

        if (newFrom > newTo) {
            if (type === 'from') {
                newTo = newFrom;
            } else {
                newFrom = newTo;
            }
        }


        const newSizeRange = `${newFrom}-${newTo}`;

        if (item) {
            setEditingItem({ ...item, sizeRange: newSizeRange });
        } else {
            setNewItem({ ...newItem, sizeRange: newSizeRange });
        }
    };


    useEffect(() => {
        if (searchTerm !== "") {
            const filtered = items.filter((product) =>
                product.link.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredItems(filtered);
        } else {
            setFilteredItems(items);
        }
    }, [items, searchTerm]);

    useEffect(() => {
        console.log(selectedFilter)
        if (selectedFilter !== "All") {
            if (selectedFilter === "Active") {
                const filtered = items.filter((product) =>
                    product.active
                );
                setFilteredItems(filtered);
            }
            else if (selectedFilter === "Disabled") {
                const filtered = items.filter((product) =>
                    !product.active
                );
                setFilteredItems(filtered);
            }
            else if (selectedFilter === "Private") {
                const filtered = items.filter((product) =>
                    product.private
                );
                setFilteredItems(filtered);
            }
            else if (selectedFilter.includes("Region")) {
                const filtered = items.filter((product) =>
                    product.region.includes(selectedFilter.split('Region ')[1])
                );
                setFilteredItems(filtered);
            }
        }
        else {
            setFilteredItems(items);
        }
    }, [items, selectedFilter]);

    const handleTooltip = (e: React.MouseEvent<Element>, content: string) => {
        const rect = e.currentTarget.getBoundingClientRect();
        const buttonCenter = rect.left + rect.width / 2;
        setTooltip({
            content,
            x: buttonCenter + window.scrollX,
            y: rect.top + window.scrollY - 5
        });
    };

    const openEditDialog = (item: MonitorInput) => {
        setEditingItem(item);
        setIsEditDialogOpen(true);
    };
    return (
        <div className="bg-[#1a2234] rounded-xl overflow-hidden">
            <div className="p-4 sm:p-6">
                <div className="flex items-center mb-6">
                    <h3 className="text-xl font-medium text-slate-100 flex items-center">Monitored Links/Products</h3>
                </div>

                {!currentMonitor.data.input_allowed && !developer ? (
                    <InputsNotAllowedMessage />
                ) : (
                    <div className="space-y-6">
                        <div className="space-y-4">
                            <div className="flex flex-col sm:flex-row gap-4">
                                <div className="relative flex-1">
                                    <input
                                        type="text"
                                        placeholder="Enter link or product ID"
                                        value={newItem.link}
                                        onChange={(e) => setNewItem({ ...newItem, link: e.target.value })}
                                        className="w-full bg-[#0f1729] rounded-lg px-3 py-2 text-slate-100 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 border border-slate-700/50"
                                    />
                                </div>

                                <div className="flex items-center gap-2">
                                    <button
                                        onClick={addItem}
                                        onMouseEnter={(e) => handleTooltip(e, "Add new input")}
                                        onMouseLeave={handleTooltipLeave}
                                        className="p-2 bg-emerald-600 hover:bg-emerald-700 text-white rounded-lg transition-colors"
                                    >
                                        <Plus size={16} />
                                    </button>

                                    <button
                                        onClick={addItemDisabled}
                                        onMouseEnter={(e) => handleTooltip(e, "Add disabled input")}
                                        onMouseLeave={handleTooltipLeave}
                                        className="p-2 bg-red-600 hover:bg-red-700 text-white rounded-lg transition-colors"
                                    >
                                        <X size={16} />
                                    </button>

                                    <button
                                        onClick={() => setShowExamples(!showExamples)}
                                        onMouseEnter={(e) => handleTooltip(e, `${showExamples ? "Hide" : "Show"} Example Inputs`)}
                                        onMouseLeave={handleTooltipLeave}
                                        className="p-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg transition-colors"
                                    >
                                        <HelpCircle size={16} />
                                    </button>
                                </div>
                            </div>

                            {(currentMonitor.webhooks.length > 1 || currentMonitor.data.monitor_type === "new") && (
                                <RegionSelect
                                    newItem={newItem}
                                    setNewItem={setNewItem}
                                    availableRegions={currentMonitor.webhooks
                                        .map(webhook => webhook.region.split('-')[0].replace('Nike', '').replace('Snkrs', ''))
                                        .filter((region, index, self) => self.indexOf(region) === index)}
                                />
                            )}

                            {showExamples && (
                                <ExampleInputs inputs={currentMonitor.data.example_inputs} />
                            )}

                            <OptionalInputs
                                showOptionalInputs={showOptionalInputs}
                                setShowOptionalInputs={setShowOptionalInputs}
                                newItem={newItem}
                                setNewItem={setNewItem}
                                handleSizeRangeChange={handleSizeRangeChange}
                                customerData={customerData}
                            />
                        </div>


                        <InputList
                            items={filteredItems}
                            availableRegions={currentMonitor.webhooks.map(webhook => webhook.region)}
                            toggleActive={toggleActive}
                            openEditDialog={openEditDialog}
                            removeItem={removeItem}
                            searchTerm={searchTerm}
                            setSearchTerm={setSearchTerm}
                            handleBlur={handleBlur}
                            selectedFilter={selectedFilter}
                            filterOptions={filterOptions}
                            setSelectedFilter={setSelectedFilter} />

                    </div>
                )}
            </div>

            {isEditDialogOpen && editingItem && (
                <EditDialog
                    editingItem={editingItem}
                    setEditingItem={setEditingItem}
                    updateItem={updateItem}
                    setIsEditDialogOpen={setIsEditDialogOpen}
                    isEditDialogOpen={isEditDialogOpen}
                    availableRegions={currentMonitor.webhooks
                        .map(webhook => webhook.region.split('-')[0].replace('Nike', '').replace('Snkrs', ''))
                        .filter((region, index, self) => self.indexOf(region) === index)}
                    handleSizeRangeChange={handleSizeRangeChange}
                    customerData={customerData}
                    handleBlur={handleBlur}
                />
            )}

            {tooltip && (
                <div
                    ref={tooltipRef}
                    className="fixed z-50 px-3 py-1.5 bg-[#1a2234] text-sm text-slate-100 rounded-lg shadow-xl border border-slate-700/50"
                    style={{
                        left: `${tooltip.x}px`,
                        top: `${tooltip.y}px`,
                        transform: 'translate(-50%, -100%)',
                        pointerEvents: 'none',
                    }}
                >
                    {tooltip.content}
                    <div
                        className="absolute left-1/2 bottom-0 w-2 h-2 bg-[#1a2234] border-r border-b border-slate-700/50 -translate-x-1/2 translate-y-1/2 rotate-45"
                    />
                </div>
            )}
        </div>
    );
};

export default MonitoredLinksSection;
