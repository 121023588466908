import { useRef } from 'react';
import { toast, Id, ToastOptions } from 'react-toastify';

export const useToastManager = () => {
    // Track the current toast
    const toastIdRef = useRef<Id | null>(null);
    // Track loading timeout
    const loadingTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    const dismissCurrentToast = () => {
        toast.dismiss();

        toastIdRef.current = null;

        if (loadingTimeoutRef.current) {
            clearTimeout(loadingTimeoutRef.current);
            loadingTimeoutRef.current = null;
        }
    };


    const showToast = (message: string, type: "info" | "success" | "error") => {
        const toastConfig: ToastOptions = {
            type,
            isLoading: type === "info",
            autoClose: type === "info" ? false : 3000,
            position: "top-right",
            closeOnClick: type !== "info",
            pauseOnHover: true,
            draggable: true,
            toastId: 'global-toast',
        };

        // Create new toast
        toastIdRef.current = toast(message, toastConfig);

        // For loading toasts, set a timeout to prevent them getting stuck
        if (type === "info") {
            loadingTimeoutRef.current = setTimeout(() => {
                if (toastIdRef.current && toast.isActive(toastIdRef.current)) {
                    toast.update(toastIdRef.current, {
                        render: "Operation timed out. Please try again.",
                        type: "error",
                        isLoading: false,
                        autoClose: 3000,
                    });
                    toastIdRef.current = null;
                }
            }, 8000);
        }
    };

    const updateToast = (message: string, type: "info" | "success" | "error") => {
        // If there's no active toast, create a new one
        if (!toastIdRef.current || !toast.isActive(toastIdRef.current)) {
            showToast(message, type);
            return;
        }

        // Clear any existing loading timeout
        if (loadingTimeoutRef.current) {
            clearTimeout(loadingTimeoutRef.current);
            loadingTimeoutRef.current = null;
        }

        // Update the existing toast
        toast.update(toastIdRef.current, {
            render: message,
            type,
            isLoading: type === "info",
            autoClose: type === "info" ? false : 3000,
        });

        // Set new loading timeout if it's an info toast
        if (type === "info") {
            loadingTimeoutRef.current = setTimeout(() => {
                if (toastIdRef.current && toast.isActive(toastIdRef.current)) {
                    toast.update(toastIdRef.current, {
                        render: "Operation timed out. Please try again.",
                        type: "error",
                        isLoading: false,
                        autoClose: 3000,
                    });
                    toastIdRef.current = null;
                }
            }, 8000);
        }

        // Clear references for final states
        if (type !== "info") {
            toastIdRef.current = null;
        }
    };

    return {
        showToast,
        updateToast,
        dismissToast: dismissCurrentToast
    };
};

export default useToastManager;