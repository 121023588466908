import React from 'react';
import { LogOut, Plus } from 'lucide-react';
import { classNames } from '../../../utils/classNames';
import { BotData, User } from '../../../types';

interface SidebarFooterProps {
    isCollapsed: boolean;
    user: User;
    botData?: BotData;
    onLogout: () => void;
}

export const SidebarFooter: React.FC<SidebarFooterProps> = ({
    isCollapsed,
    user,
    botData,
    onLogout,
}) => {
    const handleInviteBot = () => {
        if (botData?.app_id && botData?.app_id !== "N/A") {
            window.open(
                `https://discord.com/api/oauth2/authorize?client_id=${botData.app_id}&permissions=8&scope=bot`,
                '_blank'
            );
        }
        else {
            alert("The bot is not setup yet, you will receive a private message on Discord once the setup is complete.")
        }
    };

    return (
        <div className="border-t border-gray-200 dark:border-gray-800 p-4">
            {/* Invite Bot Button */}
            {!isCollapsed && (
                <button
                    onClick={handleInviteBot}
                    className={classNames(
                        "w-full flex items-center justify-center gap-2 px-4 py-2 mb-4",
                        "text-sm font-medium text-gray-700 dark:text-gray-200",
                        "bg-gray-100 dark:bg-[#0f1729] rounded-lg",
                        "hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors"
                    )}
                >
                    <Plus className="h-5 w-5" />
                    <span>Invite Bot</span>
                </button>
            )}

            {/* User Profile & Logout */}
            <div className={classNames(
                "flex items-center",
                isCollapsed ? "justify-center" : "justify-between"
            )}>
                <div className="flex items-center min-w-0">
                    <img
                        src={user.image}
                        alt={user.name}
                        className="h-8 w-8 rounded-full flex-shrink-0"
                    />
                    {!isCollapsed && (
                        <div className="ml-3 min-w-0">
                            <p className="text-sm font-medium text-gray-700 dark:text-gray-200 truncate">
                                {user.name}
                            </p>
                        </div>
                    )}
                </div>

                {!isCollapsed && (
                    <button
                        onClick={onLogout}
                        className={classNames(
                            "p-2 rounded-lg text-gray-500 dark:text-gray-400",
                            "hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors"
                        )}
                    >
                        <LogOut className="h-5 w-5" />
                    </button>
                )}
            </div>
        </div>
    );
};