import React, { useState, useEffect } from 'react';
import { X, Save, ChevronDown } from 'lucide-react';
import axios from 'axios';
import { API_URL } from '../../../../constants';
import { toast } from 'react-toastify';
import ReactDOM from 'react-dom';


interface AddCustomerPopupProps {
    monitorUuid: string;
    onClose: () => void;
    handleBlur: () => void;
}

const AddCustomerPopup: React.FC<AddCustomerPopupProps> = ({
    monitorUuid,
    onClose,

}) => {
    const [customers, setCustomers] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [selectedCustomers, setSelectedCustomers] = useState<number[]>([]);

    useEffect(() => {
        fetchCustomers();
    }, []);

    const fetchCustomers = async () => {
        try {
            // Fetch customers
            const monitorCustomersResponse = await axios.get(
                `${API_URL}/v1/monitors/${monitorUuid}/customers`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": localStorage.getItem("token")
                    }
                }
            );

            const addedCustomerIds = monitorCustomersResponse.data.filtered;



            // Combine the data
            const combinedCustomers = monitorCustomersResponse.data.all.map(
                (customer: any) => ({
                    customer_id: customer.customer_id,  // Don't create an 'id' field
                    name: customer.name,
                    is_added: addedCustomerIds.includes(customer.customer_id)
                })
            );

            setCustomers(combinedCustomers);
            setSelectedCustomers(addedCustomerIds);
            setLoading(false);
        } catch (error) {
            console.error('Failed to fetch customers:', error);
            toast.error('Failed to fetch customers');
            setLoading(false);
        }
    };

    const handleCustomerToggle = (customerId: any) => {
        setSelectedCustomers(prev =>
            prev.includes(customerId)
                ? prev.filter(id => id !== customerId)
                : [...prev, customerId]
        );
    };

    const handleSave = async () => {
        try {
            await axios.put(
                `${API_URL}/v1/monitors/${monitorUuid}/customers`,
                { customerIds: selectedCustomers },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": localStorage.getItem("token")
                    }
                }
            );

            toast.success('Customer access updated successfully');
            onClose();
        } catch (error) {
            console.error('Failed to update customers:', error);
            toast.error('Failed to update customer access');
        }
    };

    return ReactDOM.createPortal(
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
            <div className="bg-[#1a2234] rounded-xl shadow-xl w-full max-w-md mx-4">
                <div className="p-6">
                    <div className="flex items-center justify-between mb-6">
                        <h2 className="text-xl font-semibold text-white">Manage Customer Access</h2>
                        <button
                            onClick={onClose}
                            className="p-2 hover:bg-slate-800 rounded-lg transition-colors"
                        >
                            <X size={16} className="text-slate-400" />
                        </button>
                    </div>

                    <div className="space-y-4">
                        {loading ? (
                            <div className="text-center text-slate-400 py-4">Loading customers...</div>
                        ) : (
                            <div className="relative">
                                <button
                                    onClick={() => setDropdownOpen(!dropdownOpen)}
                                    className="w-full flex items-center justify-between bg-slate-800 rounded-lg px-3 py-2 text-sm text-white border border-slate-700 hover:border-slate-600 transition-colors"
                                >
                                    <span>
                                        {selectedCustomers.length} customer{selectedCustomers.length !== 1 ? 's' : ''} selected
                                    </span>
                                    <ChevronDown size={16} className="text-slate-400" />
                                </button>

                                {dropdownOpen && (
                                    <div className="absolute z-50 w-full mt-1 bg-slate-800 border border-slate-700 rounded-lg shadow-lg max-h-60 overflow-y-auto">
                                        {customers.map(customer => (
                                            <div
                                                key={customer.customer_id}
                                                className="flex items-center space-x-2 p-3 hover:bg-slate-700 cursor-pointer"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    handleCustomerToggle(customer.customer_id);
                                                }}
                                            >
                                                <input
                                                    type="checkbox"
                                                    checked={selectedCustomers.includes(customer.customer_id)}
                                                    onChange={(e) => {
                                                        e.stopPropagation();
                                                        handleCustomerToggle(customer.customer_id);
                                                    }}
                                                    className="rounded border-slate-600 text-blue-600 focus:ring-blue-500 bg-slate-700"
                                                />
                                                <div className="flex flex-col">
                                                    <span className="text-sm text-slate-300">{customer.name}</span>
                                                    {selectedCustomers.includes(customer.customer_id) && (
                                                        <span className="text-xs text-slate-400">Currently has access</span>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>

                    <div className="mt-6 flex items-center justify-end gap-3">
                        <button
                            onClick={onClose}
                            className="px-4 py-2 text-slate-300 hover:text-white text-sm transition-colors"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={handleSave}
                            disabled={loading}
                            className="flex items-center gap-2 px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white text-sm rounded-lg transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                            <Save size={16} />
                            <span>Save changes</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>, document.body
    );
};

export default AddCustomerPopup;