import React, { useState } from 'react';
import { X, Send, Plus, Trash2 } from 'lucide-react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { API_URL } from '../../../../constants';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

interface EditDialogProps {
    handleBlur: () => void;
    setNewUpdatePopupOpen: (isOpen: boolean) => void;
}

interface DiscordEmbed {
    title: string;
    color: number;
    fields: Array<{
        name: string;
        value: string;
        inline?: boolean;
    }>;
    thumbnail?: {
        url: string;
    };
}

const UpdatePopup: React.FC<EditDialogProps> = ({
    handleBlur, setNewUpdatePopupOpen
}) => {
    const [formData, setFormData] = useState({
        title: "Toolbox Update | v 1.0.0",
        color: "#3BB2B7", // Discord blue as default
        thumbnailUrl: "",
        fields: [{
            name: "Added",
            value: "",
            inline: true
        }]
    });


    const createDiscordEmbed = (): DiscordEmbed => {
        return {
            title: formData.title,
            color: parseInt(formData.color.replace("#", ""), 16),
            fields: formData.fields,
            thumbnail: formData.thumbnailUrl ? { url: formData.thumbnailUrl } : undefined
        };
    };

    const sendUpdateRequest = async () => {
        const toastId = toast.loading("Sending update to Discord...");
        try {
            const embed = createDiscordEmbed();
            const response = await axios.post(`${API_URL}/v1/updates`, {
                embed
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": localStorage.getItem("token")
                }
            });

            if (response.status === 200) {
                toast.update(toastId, {
                    render: "Update sent successfully!",
                    type: "success",
                    isLoading: false,
                    autoClose: 3000
                });

                setNewUpdatePopupOpen(false);
                handleBlur();
            }
        } catch (error) {
            toast.update(toastId, {
                render: "Failed to send update",
                type: "error",
                isLoading: false,
                autoClose: 3000
            });
            console.error('Error sending update:', error);
        }
    };

    const addField = () => {
        setFormData(prev => ({
            ...prev,
            fields: [...prev.fields, { name: "", value: "", inline: true }]
        }));
    };

    const removeField = (index: number) => {
        setFormData(prev => ({
            ...prev,
            fields: prev.fields.filter((_, i) => i !== index)
        }));
    };

    const updateField = (index: number, key: 'name' | 'value' | 'inline', value: any) => {
        setFormData(prev => ({
            ...prev,
            fields: prev.fields.map((field, i) =>
                i === index ? { ...field, [key]: value } : field
            )
        }));
    };

    return ReactDOM.createPortal(
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
            <div className="bg-[#1a2234] rounded-xl shadow-xl w-full max-w-md mx-4">
                <div className="p-6">
                    <div className="flex items-center justify-between mb-6">
                        <h2 className="text-xl font-semibold text-white">New Discord Update</h2>
                        <button
                            onClick={() => { setNewUpdatePopupOpen(false); handleBlur(); }}
                            className="p-2 hover:bg-slate-800 rounded-lg transition-colors"
                        >
                            <X size={16} className="text-slate-400" />
                        </button>
                    </div>

                    <div className="space-y-4">
                        {/* Title */}
                        <div>
                            <label className="block text-sm font-medium text-slate-300 mb-1">
                                Title
                            </label>
                            <input
                                type="text"
                                value={formData.title}
                                onChange={(e) => setFormData(prev => ({ ...prev, title: e.target.value }))}
                                className="w-full bg-slate-800 rounded-lg px-3 py-2 text-sm text-white border border-slate-700 focus:outline-none focus:ring-1 focus:ring-blue-500"
                                placeholder="Update Title"
                            />
                        </div>

                        {/* Thumbnail URL */}
                        <div>
                            <label className="block text-sm font-medium text-slate-300 mb-1">
                                Thumbnail URL (optional)
                            </label>
                            <input
                                type="url"
                                value={formData.thumbnailUrl}
                                onChange={(e) => setFormData(prev => ({ ...prev, thumbnailUrl: e.target.value }))}
                                className="w-full bg-slate-800 rounded-lg px-3 py-2 text-sm text-white border border-slate-700 focus:outline-none focus:ring-1 focus:ring-blue-500"
                                placeholder="https://example.com/image.png"
                            />
                        </div>

                        {/* Embed Color */}
                        <div>
                            <label className="block text-sm font-medium text-slate-300 mb-1">
                                Embed Color
                            </label>
                            <input
                                type="color"
                                value={formData.color}
                                onChange={(e) => setFormData(prev => ({ ...prev, color: e.target.value }))}
                                className="w-full h-10 bg-slate-800 rounded-lg px-3 py-2 text-sm text-white border border-slate-700 focus:outline-none focus:ring-1 focus:ring-blue-500"
                            />
                        </div>

                        {/* Fields */}
                        <div>
                            <label className="block text-sm font-medium text-slate-300 mb-1">
                                Fields
                            </label>
                            <div className="space-y-2">
                                {formData.fields.map((field, index) => (
                                    <div key={index} className="space-y-2">
                                        <div className="flex gap-2">
                                            <input
                                                type="text"
                                                value={field.name}
                                                onChange={(e) => updateField(index, 'name', e.target.value)}
                                                className="flex-1 bg-slate-800 rounded-lg px-3 py-2 text-sm text-white border border-slate-700 focus:outline-none focus:ring-1 focus:ring-blue-500"
                                                placeholder="Field Name"
                                            />
                                            <button
                                                onClick={() => removeField(index)}
                                                className="p-2 hover:bg-slate-700 rounded-lg transition-colors"
                                            >
                                                <Trash2 size={16} className="text-slate-400" />
                                            </button>
                                        </div>
                                        <textarea
                                            value={field.value}
                                            onChange={(e) => updateField(index, 'value', e.target.value)}
                                            className="w-full bg-slate-800 rounded-lg px-3 py-2 text-sm text-white border border-slate-700 focus:outline-none focus:ring-1 focus:ring-blue-500"
                                            placeholder="Field Value"
                                        />
                                        <label className="flex items-center gap-2 text-sm text-slate-300">
                                            <input
                                                type="checkbox"
                                                checked={field.inline}
                                                onChange={(e) => updateField(index, 'inline', e.target.checked)}
                                                className="rounded border-slate-700"
                                            />
                                            Inline Field
                                        </label>
                                    </div>
                                ))}
                                <button
                                    onClick={addField}
                                    className="flex items-center gap-2 px-3 py-2 text-sm text-slate-300 hover:text-white transition-colors"
                                >
                                    <Plus size={16} />
                                    Add Field
                                </button>
                            </div>
                        </div>


                    </div>

                    <div className="mt-6 flex items-center justify-end gap-3">
                        <button
                            onClick={() => { setNewUpdatePopupOpen(false); handleBlur(); }}
                            className="px-4 py-2 text-slate-300 hover:text-white text-sm transition-colors"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={sendUpdateRequest}
                            className="flex items-center gap-2 px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white text-sm rounded-lg transition-colors"
                        >
                            <Send size={16} />
                            <span>Send Update</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>,
        document.body
    );
};

export default UpdatePopup;