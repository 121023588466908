import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Toast theme configuration
const toastTheme = {
    dark: {
        background: '#1a2234',
        text: '#e2e8f0',
        border: 'rgb(51 65 85 / 0.5)',
        success: {
            background: 'rgb(16 185 129 / 0.1)',
            border: 'rgb(16 185 129 / 0.2)',
            text: 'rgb(52 211 153)',
            progress: '#10b981'
        },
        error: {
            background: 'rgb(239 68 68 / 0.1)',
            border: 'rgb(239 68 68 / 0.2)',
            text: 'rgb(248 113 113)',
            progress: '#ef4444'
        },
        warning: {
            background: 'rgb(245 158 11 / 0.1)',
            border: 'rgb(245 158 11 / 0.2)',
            text: 'rgb(251 191 36)',
            progress: '#f59e0b'
        },
        info: {
            background: 'rgb(59 130 246 / 0.1)',
            border: 'rgb(59 130 246 / 0.2)',
            text: 'rgb(96 165 250)',
            progress: '#3b82f6'
        },
        loading: {
            background: 'rgb(71 85 105 / 0.1)',
            border: 'rgb(71 85 105 / 0.2)',
            text: 'rgb(148 163 184)',
            progress: '#64748b'
        }
    }
};

interface CustomToastContainerProps {
    limit?: number;
}

const CustomToastContainer: React.FC<CustomToastContainerProps> = ({ limit = 1 }) => (
    <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={true}
        pauseOnHover={true}
        theme="dark"
        limit={limit}
        className="!-translate-y-2 !translate-x-2"
        toastClassName={() =>
            'relative flex p-4 min-h-[64px] rounded-xl justify-between overflow-hidden cursor-pointer bg-[#1a2234] border border-slate-700/50 mb-3'
        }
        bodyClassName={() =>
            'text-sm font-medium text-slate-200 flex items-center'
        }
        progressStyle={{
            background: '#3f4356'
        }}
        style={{
            color: toastTheme.dark.text,
        }}
    />
);

const defaultToastConfig = {
    position: "top-right" as const,
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnFocusLoss: false,
    draggable: true,
    pauseOnHover: true,
};

const customToast = {
    success: (message: string) => toast.success(message, {
        ...defaultToastConfig,
        style: {
            background: toastTheme.dark.success.background,
            borderColor: toastTheme.dark.success.border,
            color: toastTheme.dark.success.text
        },
        progressStyle: {
            background: toastTheme.dark.success.progress
        },
    }),

    error: (message: string) => toast.error(message, {
        ...defaultToastConfig,
        style: {
            background: toastTheme.dark.error.background,
            borderColor: toastTheme.dark.error.border,
            color: toastTheme.dark.error.text
        },
        progressStyle: {
            background: toastTheme.dark.error.progress
        },
    }),

    warning: (message: string) => toast.warning(message, {
        ...defaultToastConfig,
        style: {
            background: toastTheme.dark.warning.background,
            borderColor: toastTheme.dark.warning.border,
            color: toastTheme.dark.warning.text
        },
        progressStyle: {
            background: toastTheme.dark.warning.progress
        },
    }),

    info: (message: string) => toast.info(message, {
        ...defaultToastConfig,
        style: {
            background: toastTheme.dark.info.background,
            borderColor: toastTheme.dark.info.border,
            color: toastTheme.dark.info.text
        },
        progressStyle: {
            background: toastTheme.dark.info.progress
        },
    }),

    loading: (message: string) => toast.loading(message, {
        style: {
            background: toastTheme.dark.loading.background,
            borderColor: toastTheme.dark.loading.border,
            color: toastTheme.dark.loading.text
        },
        progressStyle: {
            background: toastTheme.dark.loading.progress
        },
    }),
};

export { CustomToastContainer, customToast };