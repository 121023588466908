import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_URL } from "../constants";

interface SetupProps {
    handleLogOut: () => void;
}

const Setup: React.FC<SetupProps> = ({ handleLogOut }) => {
    const [page, setPage] = useState("default");
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [formData, setFormData] = useState({
        name: 'Customer or Group Name',
        image: 'https://dashboard.solvextools.com/logo.png',
        hex: '#1c2a97',
        footerText: 'FooterText',
        footerIcon: 'https://dashboard.solvextools.com/logo.png'
    });

    useEffect(() => {
        fetchCustomerInfo();
    }, []);

    const fetchCustomerInfo = async () => {
        try {
            const response = await axios.get(`${API_URL}/v1/customer/info`, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": localStorage.getItem("token")
                }
            });

            if (response.status === 200) {
                const { has_bot, bot_pending } = response.data.data;
                setPage(has_bot && bot_pending ? "pending" : has_bot ? "default" : "start");
            }
        } catch (error) {
            handleLogOut();
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const hexRegex = /^#?([a-f0-9]{6}|[a-f0-9]{3})$/i;
        if (!hexRegex.test(formData.hex)) {
            setErrorMessage("Invalid hex color value");
            return;
        }

        try {
            const response = await axios.post(`${API_URL}/v1/create/customer`, {
                name: formData.name,
                image_url: formData.image,
                hex_color: formData.hex,
                footer_text: formData.footerText,
                footer_icon: formData.footerIcon,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": localStorage.getItem("token")
                }
            });

            if (response.status === 200) {
                setPage("pending");
            }
        } catch (error) {
            handleLogOut();
        }
    };

    if (page === "default") {
        return (
            <div className="flex flex-col w-full min-h-screen bg-[#0f1729] items-center justify-center">
                <div className="w-10 h-10 border-4 border-blue-500 border-t-transparent rounded-full animate-spin" />
            </div>
        );
    }

    if (page === "pending") {
        window.location.reload();
        return null
    }

    if (page === "start") {
        return (
            <div className="flex flex-col w-full min-h-screen bg-[#0f1729] items-center justify-center px-4">
                <div className="bg-[#1a2234] rounded-xl overflow-hidden shadow-xl max-w-md w-full p-8">
                    <div className="space-y-4 text-center">
                        <h1 className="text-2xl font-semibold text-slate-100">
                            Hey {localStorage.getItem("name")}!
                        </h1>
                        <p className="text-slate-400">
                            Seems like you don't have a customer profile yet! Click below to create one!
                        </p>
                        <button
                            onClick={() => setPage("setup")}
                            className="px-6 py-3 bg-blue-600 hover:bg-blue-700 text-white font-medium rounded-lg transition-colors"
                        >
                            Start Setup
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="flex flex-col min-h-screen bg-[#0f1729]">
            <div className="flex-1 flex items-center justify-center p-4 sm:p-8">
                <div className="max-w-6xl w-full">
                    <h1 className="text-2xl font-semibold text-slate-100 mb-6">Customer Setup</h1>

                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                        {/* Form Section */}
                        <div className="bg-[#1a2234] rounded-xl overflow-hidden p-6">
                            <form onSubmit={handleSubmit} className="space-y-4">
                                <div>
                                    <label className="block text-sm text-slate-400 mb-1">Name</label>
                                    <input
                                        type="text"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleInputChange}
                                        className="w-full bg-[#0f1729] rounded-lg px-3 py-2 text-slate-100 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 border border-slate-700/50"
                                        required
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm text-slate-400 mb-1">Image URL</label>
                                    <input
                                        type="text"
                                        name="image"
                                        value={formData.image}
                                        onChange={handleInputChange}
                                        className="w-full bg-[#0f1729] rounded-lg px-3 py-2 text-slate-100 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 border border-slate-700/50"
                                        required
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm text-slate-400 mb-1">Embed Color</label>
                                    <div className="flex gap-2">
                                        <input
                                            type="text"
                                            name="hex"
                                            value={formData.hex}
                                            onChange={handleInputChange}
                                            className="flex-1 bg-[#0f1729] rounded-lg px-3 py-2 text-slate-100 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 border border-slate-700/50"
                                            required
                                        />
                                        <input
                                            type="color"
                                            value={formData.hex}
                                            onChange={handleInputChange}
                                            name="hex"
                                            className="w-10 h-10 rounded cursor-pointer bg-transparent p-0 border border-slate-700/50"
                                        />
                                    </div>
                                </div>

                                <div>
                                    <label className="block text-sm text-slate-400 mb-1">Footer Text</label>
                                    <input
                                        type="text"
                                        name="footerText"
                                        value={formData.footerText}
                                        onChange={handleInputChange}
                                        className="w-full bg-[#0f1729] rounded-lg px-3 py-2 text-slate-100 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 border border-slate-700/50"
                                        required
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm text-slate-400 mb-1">Footer Icon URL</label>
                                    <input
                                        type="text"
                                        name="footerIcon"
                                        value={formData.footerIcon}
                                        onChange={handleInputChange}
                                        required
                                        className="w-full bg-[#0f1729] rounded-lg px-3 py-2 text-slate-100 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 border border-slate-700/50"
                                    />
                                </div>

                                {errorMessage && (
                                    <p className="text-sm text-red-400">{errorMessage}</p>
                                )}

                                <button
                                    type="submit"
                                    className="w-full px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white text-sm rounded-lg transition-colors"
                                >
                                    Create Profile
                                </button>
                            </form>
                        </div>

                        {/* Preview Section */}
                        <div className="bg-[#1a2234] rounded-xl overflow-hidden p-6">
                            <h2 className="text-lg font-medium text-slate-100 mb-4">Preview</h2>

                            <div className="space-y-4">
                                <div className="flex items-center gap-3">
                                    <img
                                        src={formData.image}
                                        alt="Preview"
                                        className="w-10 h-10 rounded-full ring-2 ring-slate-700/50"
                                    />
                                    <div className="flex items-center gap-2">
                                        <span className="text-slate-100">{formData.name}</span>
                                        <span className="px-1.5 py-0.5 text-xs bg-slate-700/50 text-slate-300 rounded">BOT</span>
                                    </div>
                                </div>

                                <div
                                    className="rounded-lg p-4 space-y-2"
                                    style={{
                                        borderLeft: `4px solid ${formData.hex}`,
                                        backgroundColor: '#0f1729'
                                    }}
                                >
                                    <h4 className="font-medium text-slate-100">Example Title</h4>
                                    <p className="text-sm text-slate-300">Example description</p>

                                    <div className="pt-2 flex items-center gap-2 text-slate-400 text-xs">
                                        {formData.footerIcon && (
                                            <img
                                                src={formData.footerIcon}
                                                alt="Footer"
                                                className="w-4 h-4 rounded-full"
                                            />
                                        )}
                                        <span>{formData.footerText}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Setup;