import React, { useState } from 'react';
import { Settings as SettingsIcon, Save, Info, Plus, X, Webhook, Link, Check, Copy } from 'lucide-react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { API_URL } from '../../constants';
import { Bot, Customer } from '../../types';

interface SettingsProps {
    botData: Bot;
    customerData: Customer;
    handleLogOut: () => void;
    bot_pending: boolean
}

export const Settings: React.FC<SettingsProps> = ({
    botData,
    customerData,
    handleLogOut,
    bot_pending
}) => {
    const [image, setImage] = useState<string>(customerData.image);
    const [footerText, setFooterText] = useState<string>(botData.footer_text);
    const [footerIcon, setFooterIcon] = useState<string>(customerData.footer_icon);
    const [hex, setHex] = useState<string>(customerData.hex_color);
    const [botName, setBotName] = useState<string>(botData.name);
    const [customerName, setCustomerName] = useState<string>(customerData.name);
    const [webhookUrl, setWebhookUrl] = useState<string>(customerData.webhook_url || '');
    const [newServer, setNewServer] = useState<string>('');
    const [servers, setServers] = useState<string[]>(
        Array.isArray(botData.whitelisted_servers) ? botData.whitelisted_servers : []
    );

    const handleRemoveServer = (serverToRemove: string) => {
        setServers(prev => prev.filter(server => server !== serverToRemove));
    };

    const handleAddServer = () => {
        if (newServer.trim() && !servers.includes(newServer)) {
            setServers(prev => [...prev, newServer]);
            setNewServer('');
        }
    };

    const updateSettings = async () => {
        const toastId = toast.loading("Saving settings...");

        try {
            const response = await axios.post(`${API_URL}/v1/customer/update`, {
                bot_name: botName,
                customer_name: customerName,
                image_url: image,
                hex_color: hex,
                footer_text: footerText,
                footer_icon: footerIcon,
                app_id: botData.app_id,
                whitelisted_servers: servers,
                webhook_url: webhookUrl
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": localStorage.getItem("token")
                }
            });

            if (response.status === 200) {
                toast.update(toastId, {
                    render: "Settings updated successfully",
                    type: "success",
                    isLoading: false,
                    autoClose: 3000
                });
            }
        } catch (error) {
            toast.update(toastId, {
                render: "Failed to update settings",
                type: "error",
                isLoading: false,
                autoClose: 3000
            });
            handleLogOut();
        }
    };

    return (
        <div className="flex flex-col w-full min-h-screen bg-[#0f1729] scrollable">
            <div className="w-full px-4 sm:px-8 py-6">
                <h1 className="text-4xl text-white font-bold mb-6">Settings</h1>
                {bot_pending && (
                    <div className="max-w-3xl mx-auto mb-6">
                        <div className="bg-yellow-500/10 border border-yellow-500/20 rounded-lg p-4">
                            <div className="flex items-center gap-4">
                                <Info className="text-yellow-500" size={70} />
                                <div>
                                    <h3 className="text-yellow-500 font-medium mb-1">Bot Setup in Progress</h3>
                                    <p className="text-yellow-500/90 text-sm">
                                        Your bot is currently being set up. You will receive a private message on Discord once the setup is complete.
                                        Until then, some of the data displayed here may not be up to date. You can continue customizing your settings,
                                        and they will be applied once the bot is ready.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div className="max-w-3xl mx-auto space-y-6">
                    {/* Main Settings Container */}
                    <div className="bg-[#1a2234] rounded-xl overflow-hidden">
                        <div className="p-4 sm:p-6">
                            <div className="flex items-center justify-between pb-2">
                                <div className="flex items-center gap-2">
                                    <h2 className="text-2xl font-semibold text-slate-100">Bot Configuration</h2>
                                    <SettingsIcon className="text-slate-400" size={24} />
                                </div>
                            </div>

                            <div className="mt-6 space-y-4">
                                <div>
                                    <label className="block text-sm text-slate-400 mb-1">Bot Name</label>
                                    <input
                                        type="text"
                                        value={botName}
                                        onChange={(e) => setBotName(e.target.value)}
                                        className="w-full bg-[#0f1729] rounded-lg px-3 py-2 text-slate-100 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 border border-slate-700/50"
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm text-slate-400 mb-1">Customer Name</label>
                                    <input
                                        type="text"
                                        value={customerName}
                                        onChange={(e) => setCustomerName(e.target.value)}
                                        className="w-full bg-[#0f1729] rounded-lg px-3 py-2 text-slate-100 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 border border-slate-700/50"
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm text-slate-400 mb-1">Bot Image URL</label>
                                    <input
                                        type="text"
                                        value={image}
                                        onChange={(e) => setImage(e.target.value)}
                                        className="w-full bg-[#0f1729] rounded-lg px-3 py-2 text-slate-100 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 border border-slate-700/50"
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm text-slate-400 mb-1">Footer Text</label>
                                    <input
                                        type="text"
                                        value={footerText}
                                        onChange={(e) => setFooterText(e.target.value)}
                                        className="w-full bg-[#0f1729] rounded-lg px-3 py-2 text-slate-100 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 border border-slate-700/50"
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm text-slate-400 mb-1">Footer Icon URL</label>
                                    <input
                                        type="text"
                                        value={footerIcon}
                                        onChange={(e) => setFooterIcon(e.target.value)}
                                        className="w-full bg-[#0f1729] rounded-lg px-3 py-2 text-slate-100 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 border border-slate-700/50"
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm text-slate-400 mb-1">Embed Color</label>
                                    <div className="flex gap-2">
                                        <input
                                            type="text"
                                            value={hex}
                                            onChange={(e) => setHex(e.target.value)}
                                            className="flex-1 bg-[#0f1729] rounded-lg px-3 py-2 text-slate-100 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 border border-slate-700/50"
                                        />
                                        <input
                                            type="color"
                                            value={hex}
                                            onChange={(e) => setHex(e.target.value)}
                                            className="h-9 w-9 rounded cursor-pointer bg-transparent p-0 border border-slate-700/50"
                                        />
                                    </div>
                                </div>

                                <div>
                                    <label className="block text-sm text-slate-400 mb-1">Whitelisted Servers</label>
                                    <div className="space-y-2">
                                        {servers.map((server) => (
                                            <div
                                                key={server}
                                                className="flex items-center justify-between p-2 bg-[#0f1729] border border-slate-700/50 rounded-lg"
                                            >
                                                <span className="text-slate-200 text-sm">{server}</span>
                                                <button
                                                    onClick={() => handleRemoveServer(server)}
                                                    className="text-red-400 hover:text-red-300 transition-colors p-1"
                                                >
                                                    <X size={16} />
                                                </button>
                                            </div>
                                        ))}
                                        <div className="flex gap-2">
                                            <input
                                                type="text"
                                                value={newServer}
                                                onChange={(e) => setNewServer(e.target.value)}
                                                placeholder="Enter server ID"
                                                className="flex-1 bg-[#0f1729] rounded-lg px-3 py-2 text-slate-100 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 border border-slate-700/50"
                                            />
                                            <button
                                                onClick={handleAddServer}
                                                className="px-3 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg transition-colors"
                                            >
                                                <Plus size={16} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <label className="block text-sm text-slate-400 mb-1">Discord Webhook URL</label>
                                    <input
                                        type="text"
                                        value={webhookUrl}
                                        onChange={(e) => setWebhookUrl(e.target.value)}
                                        placeholder="https://discord.com/api/webhooks/..."
                                        className="w-full bg-[#0f1729] rounded-lg px-3 py-2 text-slate-100 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 border border-slate-700/50"
                                    />
                                    <p className="mt-1.5 text-xs text-slate-400">
                                        Add a Discord webhook to receive important updates directly in your server
                                    </p>
                                </div>
                            </div>

                            <div className="mt-6 flex justify-end">
                                <button
                                    onClick={updateSettings}
                                    className="flex items-center gap-2 px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white text-sm rounded-lg transition-colors"
                                >
                                    <Save size={16} />
                                    <span>Save Changes</span>
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* Preview Container */}
                    <div className="bg-[#1a2234] rounded-xl overflow-hidden">
                        <div className="p-4 sm:p-6">
                            <h2 className="text-2xl font-semibold text-slate-100 mb-4">Preview</h2>

                            {/* Discord-like Preview */}
                            <div className="rounded-lg p-4">
                                {/* Bot Header */}
                                <div className="flex items-center gap-3 mb-4">
                                    <img
                                        src={image}
                                        alt="Bot"
                                        className="w-8 h-8 rounded-full"
                                        onError={(e) => {
                                            const target = e.target as HTMLImageElement;
                                            target.src = 'https://cdn.discordapp.com/embed/avatars/0.png'; // Discord default avatar
                                        }}
                                    />
                                    <div className="flex items-center gap-2">
                                        <span className="font-medium text-slate-100">{botName}</span>
                                        <span className="px-1.5 py-0.5 bg-[#1a2234] text-slate-300 text-xs rounded">BOT</span>
                                    </div>
                                </div>

                                {/* Embed Preview */}
                                <div
                                    className="rounded-lg p-4 space-y-2"
                                    style={{
                                        borderLeft: `4px solid ${hex}`,
                                        backgroundColor: '#2b2d31'
                                    }}
                                >
                                    <h4 className="font-medium text-slate-100">Example Title</h4>
                                    <p className="text-sm text-slate-300">This is an example description for the embed preview.</p>

                                    {/* Footer */}
                                    <div className="pt-2 flex items-center gap-2 text-slate-400 text-xs">
                                        {footerIcon && (
                                            <img
                                                src={footerIcon}
                                                alt="Footer"
                                                className="w-4 h-4 rounded-full"
                                                onError={(e) => {
                                                    const target = e.target as HTMLImageElement;
                                                    target.src = 'https://cdn.discordapp.com/embed/avatars/0.png';
                                                }}
                                            />
                                        )}
                                        <span>{footerText}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Settings;