import useToastManager from "../../../../hooks/useToastManager";
import React, { useState, useEffect } from "react";
import { ArrowLeft, ChevronDown } from 'lucide-react';
import WebhookSection from './WebhookSection';
import MonitorDetailsSection from './MonitorDetailsSection';
import MonitoredLinksSection from './MonitoredLinksSection';
import { MonitorSettingsProps, StatusCode } from '../../../../types';
import axios, { AxiosError } from "axios";
import { API_URL } from "../../../../constants";
import { CustomToastContainer } from "../../../common/CustomToastContainer";
import StatusCodeGraph from "./StatusCodeGraph";
import { useLoading } from "../../../../contexts/LoadingContext";

const MonitorSettings: React.FC<MonitorSettingsProps> = ({
    currentMonitor,
    handleBackToTable,
    setCurrentMonitor,
    handleLogOut,
    tempMonitor,
    setTempMonitor,
    handleBlur,
    developer,
    customerData
}) => {
    const [hasChanged, setHasChanged] = useState(false);
    const [showGraph, setShowGraph] = useState<boolean>(false);
    const [statusCodes, setStatusCodes] = useState<StatusCode[]>([]);
    const { setIsLoading } = useLoading();
    const { showToast, updateToast, dismissToast } = useToastManager();

    useEffect(() => {
        if (hasChanged) {
            void updateWebhooks();
        }
    }, [currentMonitor]);

    const updateWebhooks = async (): Promise<void> => {
        if (!currentMonitor) {
            updateToast("No monitor selected", "error");
            return;
        }

        try {
            showToast("Updating webhooks...", "info");

            const webhooksData = currentMonitor.webhooks.map((webhook) => ({
                region: webhook.region,
                webhook_url: webhook.webhook_url,
            }));

            const response = await axios.post(
                `${API_URL}/v1/monitors/${currentMonitor.data.monitor_uuid}/webhooks`,
                { webhooks: webhooksData },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": localStorage.getItem("token") || "",
                    }
                }
            );

            if (response.status === 200) {
                updateToast("Webhooks updated successfully", "success");
            } else {
                throw new Error("Unexpected response status");
            }
        } catch (error) {
            console.error('Failed to update webhooks:', error);
            let errorMessage = "Failed to update webhooks";

            if (axios.isAxiosError(error)) {
                const axiosError = error as AxiosError;
                if (axiosError.response?.status === 401) {
                    errorMessage = "Authentication failed. Please log in again.";
                    handleLogOut();
                } else if (axiosError.response?.status === 404) {
                    errorMessage = "Monitor not found";
                } else if (axiosError.response?.status === 400) {
                    errorMessage = "Invalid webhook data";
                }
            }
            updateToast(errorMessage, "error");
        } finally {
            setHasChanged(false);
        }
    };

    const getStatusCodes = async (): Promise<void> => {
        if (!currentMonitor) {
            updateToast("No monitor selected", "error");
            return;
        }

        try {
            setIsLoading(true);
            showToast("Fetching status codes...", "info");

            const response = await axios.get(
                `${API_URL}/v1/monitors/${currentMonitor.data.monitor_uuid}/graph`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": localStorage.getItem("token") || "",
                    }
                }
            );

            if (response.status === 200 && response.data?.data) {
                setStatusCodes(response.data.data);
                updateToast("Status codes fetched successfully", "success");
            } else {
                throw new Error("Invalid response format");
            }
        } catch (error) {
            console.error('Failed to fetch status codes:', error);
            let errorMessage = "Failed to fetch status codes";

            if (axios.isAxiosError(error)) {
                const axiosError = error as AxiosError;
                if (axiosError.response?.status === 401) {
                    errorMessage = "Authentication failed. Please log in again.";
                    handleLogOut();
                } else if (axiosError.response?.status === 404) {
                    errorMessage = "Monitor data not found";
                }
            }

            updateToast(errorMessage, "error");
        } finally {
            setIsLoading(false);
        }
    };

    const handleGraphButtonClick = async (): Promise<void> => {
        if (!showGraph) {
            await getStatusCodes();
        } else {
            setStatusCodes([]);
        }
        setShowGraph(!showGraph);
    };

    useEffect(() => {
        return () => {
            dismissToast();
        };
    }, []);

    return (
        <div className="flex flex-col w-full min-h-screen bg-[#0f1729] scrollable">
            <div className="w-full px-4 sm:px-8 py-6">
                {developer && (
                    <div className="mb-6">
                        <button
                            onClick={handleGraphButtonClick}
                            className="flex items-center gap-2 px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white text-sm rounded-lg transition-colors"
                        >
                            <ChevronDown
                                size={16}
                                className={`transform transition-transform ${showGraph ? 'rotate-180' : ''}`}
                            />
                            <span>{showGraph ? "Hide" : "Show"} Graph</span>
                        </button>
                    </div>
                )}

                {showGraph ? (
                    <div className="bg-[#1a2234] rounded-xl overflow-hidden h-[700px] mb-6">
                        <StatusCodeGraph data={statusCodes} />
                    </div>
                ) : currentMonitor && (
                    <div className="max-w-5xl mx-auto space-y-6">
                        <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4">
                            <div>
                                <h2 className="text-2xl font-semibold text-slate-100">
                                    {currentMonitor.data.monitor_name}
                                </h2>
                                <div className="mt-2">
                                    <span className="text-sm text-slate-400">Category: </span>
                                    <span className="text-sm text-slate-300">
                                        {currentMonitor.data.product_name}
                                    </span>
                                </div>
                            </div>

                            <button
                                onClick={handleBackToTable}
                                className="flex items-center gap-2 text-blue-400 hover:text-blue-300 transition-colors text-sm"
                            >
                                <ArrowLeft size={16} />
                                <span>Back to Monitor List</span>
                            </button>
                        </div>

                        {developer && currentMonitor.monitorSettings && (
                            <MonitorDetailsSection
                                setIsLoading={setIsLoading}
                                currentMonitor={currentMonitor}
                                setCurrentMonitor={setCurrentMonitor}
                                handleBlur={handleBlur}
                            />
                        )}

                        {currentMonitor.webhooks.length > 0 && (
                            <WebhookSection
                                currentMonitor={currentMonitor}
                                setCurrentMonitor={setCurrentMonitor}
                                setHasChanged={setHasChanged}
                            />
                        )}

                        <MonitoredLinksSection
                            currentMonitor={currentMonitor}
                            setCurrentMonitor={setCurrentMonitor}
                            tempMonitor={tempMonitor}
                            setTempMonitor={setTempMonitor}
                            showToast={updateToast}
                            developer={developer}
                            customerData={customerData}
                            handleBlur={handleBlur}
                        />
                    </div>
                )}
            </div>
            <CustomToastContainer />
        </div>
    );
};

export default MonitorSettings;