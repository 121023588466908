import React, { useEffect, useState } from 'react';
import { CreditCard, Package, Monitor, Check, Info, Sparkles } from 'lucide-react';
import axios from 'axios';
import { API_URL, BASICTOOLBOX } from '../../../constants';
import { useLoading } from '../../../contexts/LoadingContext';

interface ToolboxOption {
    id: string;
    name: string;
    description: string;
    features: string[];
    popular?: boolean;
    price: {
        id: string;
        currency: string;
        unit_amount: number;
    };
    type: string;
}

interface MonitorOption {
    id: string;
    name: string;
    description: string;
    features: string[];
    popular?: boolean;
    price: {
        id: string;
        currency: string;
        unit_amount: number;
    };
    type: string;
}

interface CheckoutProps { }

const ProductCheckout: React.FC<CheckoutProps> = () => {
    const [selectedToolboxes, setSelectedToolboxes] = useState<string[]>([]);
    const [paymentLink, setPaymentLink] = useState<string>('');
    const [selectedMonitors, setSelectedMonitors] = useState<string[]>([]);
    const [toolboxOptions, setToolboxOptions] = useState<ToolboxOption[]>([]);
    const [monitorOptions, setMonitorOptions] = useState<MonitorOption[]>([]);

    const BASIC_TOOLBOX = BASICTOOLBOX;

    const { setIsLoading } = useLoading();

    const validateKey = async (key: string) => {
        try {
            const response = await axios.post(
                `${API_URL}/v1/validate/bot/invite`,
                { key },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": localStorage.getItem("token")
                    }
                }
            );

            if (response.status === 200 && !response.data.data.error) {
                window.location.search = '';
                window.location.reload();
            }
        } catch (error) {
            console.error('Key validation failed:', error);
        }
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const key = searchParams.get('key');
        const type = searchParams.get('type');


        if (key && type === "invite") {
            validateKey(key);
        }
        else {
            getProducts()
        }
    }, []);

    useEffect(() => {
        if (paymentLink !== "") {
            window.location.href = paymentLink;
        }
    }, [paymentLink]);

    const getProducts = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${API_URL}/v1/stripe/products`, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": localStorage.getItem("token")
                }
            });

            if (response.status === 200) {
                setMonitorOptions(response.data.monitor);
                setToolboxOptions(response.data.toolbox);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };


    const getPaymentLink = async () => {
        try {
            const toolboxesToPurchase = selectedToolboxes.filter(toolboxId => {
                return !(toolboxId === BASIC_TOOLBOX && (selectedMonitors.length > 0 || selectedToolboxes.length > 1));
            });
            setIsLoading(true);
            const response = await axios.post(`${API_URL}/v1/stripe/link`, {
                toolboxes: toolboxesToPurchase,
                monitors: selectedMonitors,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": localStorage.getItem("token")
                }
            });

            if (response.status === 200) {
                setPaymentLink(response.data.payment_link);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const startCheckout = () => {
        getPaymentLink();
    };

    const toggleToolbox = (toolboxId: string): void => {
        setSelectedToolboxes(prev => {
            if (prev.includes(toolboxId)) {
                // If removing the basic toolbox and monitors are selected, don't remove it
                if (toolboxId === BASIC_TOOLBOX && selectedMonitors.length > 0) {
                    return prev;
                }
                return prev.filter(id => id !== toolboxId);
            }
            // If selecting non-basic toolbox, include basic toolbox automatically
            if (toolboxId !== BASIC_TOOLBOX && !prev.includes(BASIC_TOOLBOX)) {
                return [...prev, toolboxId, BASIC_TOOLBOX];
            }
            return [...prev, toolboxId];
        });
    };

    const toggleMonitor = (monitorId: string): void => {
        setSelectedMonitors(prev => {
            const newMonitors = prev.includes(monitorId)
                ? prev.filter(id => id !== monitorId)
                : [...prev, monitorId];

            // Add basic toolbox if selecting first monitor
            if (newMonitors.length > 0 && !selectedToolboxes.includes(BASIC_TOOLBOX)) {
                setSelectedToolboxes(prev => [...prev, BASIC_TOOLBOX]);
            }
            // Remove basic toolbox if no monitors and no other toolboxes
            if (newMonitors.length === 0 && selectedToolboxes.length === 1 && selectedToolboxes[0] === BASIC_TOOLBOX) {
                setSelectedToolboxes([]);
            }
            return newMonitors;
        });
    };

    const calculateTotal = (): string => {
        let total = 0;

        // Calculate toolboxes total
        selectedToolboxes.forEach(toolboxId => {
            // Skip basic toolbox if it should be free
            if (toolboxId === BASIC_TOOLBOX && (selectedMonitors.length > 0 || selectedToolboxes.length > 1)) {
                return;
            }
            const toolbox = toolboxOptions.find(opt => opt.id === toolboxId);
            if (toolbox) {
                total += toolbox.price.unit_amount;
            }
        });

        // Add monitor prices
        selectedMonitors.forEach(monitorId => {
            const monitor = monitorOptions.find(opt => opt.id === monitorId);
            if (monitor) {
                total += monitor.price.unit_amount;
            }
        });

        return total.toFixed(2);
    };

    const isBasicToolboxFree = selectedMonitors.length > 0 ||
        (selectedToolboxes.length > 0 && !selectedToolboxes.every(id => id === BASIC_TOOLBOX));

    return (
        <div className="flex flex-col w-full min-h-screen bg-gradient-to-b from-slate-900 to-slate-800 scrollable">
            <div className="w-full px-4 py-8">
                <div className="max-w-4xl mx-auto">
                    <div className="flex items-center justify-between mb-8">
                        <h1 className="text-4xl bg-gradient-to-r from-white to-blue-400 bg-clip-text text-transparent font-bold">
                            Choose Your Plan
                        </h1>
                        <div className="text-sm text-slate-400">
                            All prices in EUR
                        </div>
                    </div>

                    {/* Info Banner */}
                    <div className="mb-8 bg-blue-500/10 border border-blue-500/20 rounded-xl p-4 text-sm text-blue-300">
                        <div className="flex items-start gap-2">
                            <Info size={20} className="flex-shrink-0 mt-0.5" />
                            <p>Basic Toolbox is included for free with any Monitor or higher-tier Toolbox. Select multiple Toolboxes to combine their features.</p>
                        </div>
                    </div>

                    <div className="space-y-8">
                        {/* Toolbox Section */}
                        <div className="bg-gradient-to-br from-slate-800 to-slate-900 rounded-2xl overflow-hidden shadow-xl border border-slate-700/50">
                            <div className="p-8">
                                <div className="flex items-center gap-3 mb-6">
                                    <Package className="text-blue-400" size={28} />
                                    <h2 className="text-2xl font-semibold text-white">Toolbox</h2>
                                    <span className="text-sm text-slate-400">(Select multiple)</span>
                                </div>

                                <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
                                    {toolboxOptions.map((option) => (
                                        <button
                                            key={option.id}
                                            onClick={() => toggleToolbox(option.id)}
                                            className={`relative p-6 rounded-xl border-2 transition-all duration-200 ${selectedToolboxes.includes(option.id)
                                                ? 'border-blue-500 bg-blue-500/10 shadow-lg shadow-blue-500/10'
                                                : 'border-slate-700 hover:border-blue-500/50 bg-slate-800/50'
                                                }`}
                                        >
                                            {option.popular && (
                                                <div className="absolute -top-3 -right-3">
                                                    <div className="flex items-center gap-1 bg-blue-500 text-white px-3 py-1 rounded-full text-xs font-medium">
                                                        <Sparkles size={12} />
                                                        Popular
                                                    </div>
                                                </div>
                                            )}
                                            {option.id === BASIC_TOOLBOX && isBasicToolboxFree && (
                                                <div className="absolute -top-3 -right-3">
                                                    <div className="flex items-center gap-1 bg-green-500 text-white px-3 py-1 rounded-full text-xs font-medium">
                                                        Included
                                                    </div>
                                                </div>
                                            )}
                                            <div className="space-y-4">
                                                <div className="space-y-2">
                                                    <div className="text-lg font-semibold text-white">{option.name}</div>
                                                    <div className="text-2xl font-bold text-blue-400">
                                                        {option.id === BASIC_TOOLBOX && isBasicToolboxFree ? (
                                                            <span className="text-green-400">Free</span>
                                                        ) : (
                                                            <>€{(option.price.unit_amount).toFixed(2)}<span className="text-sm font-normal text-slate-400">/mo</span></>
                                                        )}
                                                    </div>
                                                </div>
                                                <ul className="space-y-2">
                                                    {option.features.map((feature, index) => (
                                                        <li key={index} className="flex items-center gap-2 text-sm text-slate-300">
                                                            <Check size={14} className="text-blue-400" />
                                                            {feature}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </button>
                                    ))}
                                </div>
                            </div>
                        </div>

                        {/* Monitors Section */}
                        <div className="bg-gradient-to-br from-slate-800 to-slate-900 rounded-2xl overflow-hidden shadow-xl border border-slate-700/50">
                            <div className="p-8">
                                <div className="flex items-center gap-3 mb-6">
                                    <Monitor className="text-blue-400" size={28} />
                                    <h2 className="text-2xl font-semibold text-white">Monitors</h2>
                                    <span className="text-sm text-slate-400">(Select multiple)</span>
                                </div>

                                <div className="max-w-4xl mx-auto">
                                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                                        {monitorOptions.map((option) => (
                                            <button
                                                key={option.id}
                                                onClick={() => toggleMonitor(option.id)}
                                                className={`relative p-6 rounded-xl border-2 transition-all duration-200 ${selectedMonitors.includes(option.id)
                                                        ? 'border-blue-500 bg-blue-500/10 shadow-lg shadow-blue-500/10'
                                                        : 'border-slate-700 hover:border-blue-500/50 bg-slate-800/50'
                                                    }`}
                                            >
                                                {option.popular && (
                                                    <div className="absolute -top-3 -right-3">
                                                        <div className="flex items-center gap-1 bg-blue-500 text-white px-3 py-1 rounded-full text-xs font-medium">
                                                            <Sparkles size={12} />
                                                            Popular
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="space-y-4">
                                                    <div className="space-y-2">
                                                        <div className="text-lg font-semibold text-white">{option.name}</div>
                                                        <div className="text-2xl font-bold text-blue-400">
                                                            €{(option.price.unit_amount).toFixed(2)}
                                                            <span className="text-sm font-normal text-slate-400">/mo</span>
                                                        </div>
                                                    </div>
                                                    <ul className="space-y-2">
                                                        {option.features.map((feature, index) => (
                                                            <li key={index} className="flex items-center gap-2 text-sm text-slate-300">
                                                                <Check size={14} className="text-blue-400" />
                                                                {feature}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Checkout Summary */}
                        <div className="bg-gradient-to-br from-slate-800 to-slate-900 rounded-2xl overflow-hidden shadow-xl border border-slate-700/50">
                            <div className="p-8">
                                <div className="flex items-center justify-between mb-6">
                                    <div className="flex items-center gap-3">
                                        <CreditCard className="text-blue-400" size={28} />
                                        <h2 className="text-2xl font-semibold text-white">Summary</h2>
                                    </div>
                                    <div className="text-3xl font-bold text-white">
                                        €{calculateTotal()}<span className="text-sm font-normal text-slate-400">/mo</span>
                                    </div>
                                </div>

                                <div className="mb-8">
                                    <div className="flex items-start gap-2 text-sm text-slate-400 bg-slate-800/50 p-4 rounded-lg border border-slate-700/50">
                                        <Info size={16} className="mt-0.5" />
                                        <p>Selected products will be billed monthly. You can cancel or change your subscription at any time.</p>
                                    </div>
                                </div>

                                <button
                                    onClick={startCheckout}
                                    disabled={selectedToolboxes.length === 0 && selectedMonitors.length === 0}
                                    className={`w-full py-4 bg-gradient-to-r ${selectedToolboxes.length === 0 && selectedMonitors.length === 0
                                        ? 'from-slate-600 to-slate-500 cursor-not-allowed opacity-50'
                                        : 'from-blue-600 to-blue-500 hover:from-blue-500 hover:to-blue-400'
                                        } text-white font-semibold rounded-xl flex items-center justify-center gap-2 transition-all duration-200 shadow-lg shadow-blue-500/20`}
                                >
                                    <CreditCard size={20} />
                                    Complete Purchase
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductCheckout;