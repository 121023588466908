// PaymentErrorPage.tsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AlertTriangle } from 'lucide-react';

const PaymentErrorPage: React.FC = () => {
    const navigate = useNavigate();

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-50">
            <div className="max-w-md w-full p-6 bg-white rounded-lg shadow-lg">
                <div className="text-center">
                    <AlertTriangle className="mx-auto h-12 w-12 text-red-500 mb-4" />
                    <h1 className="text-2xl font-bold text-gray-900 mb-4">
                        Payment Processing Issue
                    </h1>
                    <p className="text-gray-600 mb-6">
                        We're having trouble confirming your payment. This could be due to:
                    </p>

                    <ul className="text-left text-gray-600 mb-6 space-y-2">
                        <li className="flex items-start">
                            <span className="mr-2">•</span>
                            <span>Temporary system delay</span>
                        </li>
                        <li className="flex items-start">
                            <span className="mr-2">•</span>
                            <span>Network connectivity issues</span>
                        </li>
                        <li className="flex items-start">
                            <span className="mr-2">•</span>
                            <span>Payment verification timeout</span>
                        </li>
                    </ul>

                    <div className="space-y-4">
                        <button
                            onClick={() => window.location.reload()}
                            className="w-full bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-colors"
                        >
                            Try Again
                        </button>

                        <button
                            onClick={() => navigate('/payment')}
                            className="w-full bg-gray-100 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-200 transition-colors"
                        >
                            Return to Payment
                        </button>
                        <a
                            href="https://discord.gg/ZTqv2QcZnn"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="block w-full text-sm text-gray-500 hover:text-gray-700"
                        >
                            Need help? Join our Discord Support Server
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentErrorPage;