import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import MonitorTable from "./MonitorTable";

import ProxySettings from "./ProxySettings";
import KeywordSettings from "./KeywordSettings";
import { API_URL } from "../../../constants";
import { Monitor, MonitorProps, MonitorData } from "../../../types";
import { useLoading } from "../../../contexts/LoadingContext";
import DeveloperSettings from "./DeveloperSettings";


const Monitors: React.FC<MonitorProps> = ({ handleLogOut, developer, handleBlur, customerData }) => {

    const navigate = useNavigate();
    const { setIsLoading } = useLoading();
    const [searchParams] = useSearchParams();


    const [monitorsPreview, setMonitorsPreview] = useState<MonitorData[]>([]);

    useEffect(() => {
        const getMonitors = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`${API_URL}/v1/monitors`, {
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": localStorage.getItem("token") || ""
                    }
                });

                if (response.status === 200) {
                    const data = response.data.data.monitors;
                    setMonitorsPreview(data);
                }
            } catch (error) {
                handleLogOut();
            }
            finally {
                setIsLoading(false);
            }
        }
        if (monitorsPreview.length === 0) {
            getMonitors()
        }

    }, [handleLogOut]);



    const handleEdit = (monitor: MonitorData) => {
        const monitorTemp: Monitor = {
            data: monitor,
            webhooks: [],
            inputs: []
        };
        const currentParams = searchParams.toString();

        navigate(`/monitors/${monitorTemp.data.monitor_uuid}?${currentParams}`);
    };


    return (
        <div>
            {developer && <DeveloperSettings developer={developer} handleBlur={handleBlur} />}
            {monitorsPreview && (
                <MonitorTable
                    monitorsPreview={monitorsPreview}
                    handleEdit={handleEdit}
                    developer={developer} handleBlur={handleBlur} />
            )}
        </div>
    );
};

export default Monitors;
