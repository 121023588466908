import React, { useEffect, useState } from 'react';
import { X, Save, ChevronDown, Plus, Trash2 } from 'lucide-react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { API_URL } from '../../../../constants';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

interface EditDialogProps {
    handleBlur: () => void;
    setNewMonitorPopupOpen: (isOpen: boolean) => void;
}

interface Product {
    id: number;
    name: string;
    type: string;
}

const monitorTypes = ["new", "product"];
const monitorVisble = [false, true];

const NewMonitorPopup: React.FC<EditDialogProps> = ({
    handleBlur, setNewMonitorPopupOpen
}) => {
    const [formData, setFormData] = useState({
        site_name: "",
        monitor_type: "product",
        monitor_visible: true,
        product_id: 5,
        example_inputs: ["Test Input"],
        regions: ["EU"]
    });

    const navigate = useNavigate();

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [typeDropdownOpen, setTypeDropdownOpen] = useState(false);
    const [visibleDropdownOpen, setVisibleDropdownOpen] = useState(false);
    const [products, setProducts] = useState<Product[]>([]);

    const createMonitorRequest = async () => {
        const toastId = toast.loading("Creating monitor...");
        try {
            const response = await axios.post(`${API_URL}/v1/monitors`, formData, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": localStorage.getItem("token")
                }
            });

            if (response.status === 200) {
                toast.update(toastId, {
                    render: "Monitor created successfully!",
                    type: "success",
                    isLoading: false,
                    autoClose: 3000
                });
                const newUuid = response.data.uuid
                navigate(`/monitors/${newUuid}`);
            }
        } catch (error) {
            if (axios.isAxiosError(error) && error.response?.status === 409) {
                toast.update(toastId, {
                    render: "A monitor with this name already exists",
                    type: "error",
                    isLoading: false,
                    autoClose: 3000
                });
            } else {
                toast.update(toastId, {
                    render: "Failed to create monitor",
                    type: "error",
                    isLoading: false,
                    autoClose: 3000
                });
            }
        }
    }

    const getProducts = async () => {
        try {
            const response = await axios.get<Product[]>(`${API_URL}/v1/products?type=monitor`, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": localStorage.getItem("token") || ""
                }
            });
            if (response.status === 200) {
                setProducts(response.data);
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            getProducts()
        };
        fetchData();
    }, []);

    const handleSubmit = async () => {
        console.log(formData);
        await createMonitorRequest()

        setNewMonitorPopupOpen(false);
        handleBlur();
    };

    const addInput = (field: 'example_inputs' | 'regions') => {
        setFormData(prev => ({
            ...prev,
            [field]: [...prev[field], ""]
        }));
    };

    const removeInput = (field: 'example_inputs' | 'regions', index: number) => {
        setFormData(prev => ({
            ...prev,
            [field]: prev[field].filter((_, i) => i !== index)
        }));
    };

    const updateInput = (field: 'example_inputs' | 'regions', index: number, value: string) => {
        setFormData(prev => ({
            ...prev,
            [field]: prev[field].map((item, i) => i === index ? value : item)
        }));
    };

    return ReactDOM.createPortal(
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
            <div className="bg-[#1a2234] rounded-xl shadow-xl w-full max-w-md mx-4">
                <div className="p-6">
                    <div className="flex items-center justify-between mb-6">
                        <h2 className="text-xl font-semibold text-white">New Monitor</h2>
                        <button
                            onClick={() => { setNewMonitorPopupOpen(false); handleBlur() }}
                            className="p-2 hover:bg-slate-800 rounded-lg transition-colors"
                        >
                            <X size={16} className="text-slate-400" />
                        </button>
                    </div>

                    <div className="space-y-4">
                        {/* Site Name */}
                        <div>
                            <label className="block text-sm font-medium text-slate-300 mb-1">
                                Site Name
                            </label>
                            <input
                                type="text"
                                value={formData.site_name}
                                onChange={(e) => setFormData(prev => ({ ...prev, site_name: e.target.value }))}
                                className="w-full bg-slate-800 rounded-lg px-3 py-2 text-sm text-white border border-slate-700 focus:outline-none focus:ring-1 focus:ring-blue-500"
                            />
                        </div>

                        {/* Monitor Type Dropdown */}
                        <div>
                            <label className="block text-sm font-medium text-slate-300 mb-1">
                                Monitor Type
                            </label>
                            <div className="relative">
                                <button
                                    type="button"
                                    onClick={() => setTypeDropdownOpen(!typeDropdownOpen)}
                                    className="w-full flex items-center justify-between bg-slate-800 rounded-lg px-3 py-2 text-sm text-white border border-slate-700 hover:border-slate-600 transition-colors"
                                >
                                    <span>{formData.monitor_type}</span>
                                    <ChevronDown size={16} className="text-slate-400" />
                                </button>

                                {typeDropdownOpen && (
                                    <div className="absolute z-50 w-full mt-1 bg-slate-800 border border-slate-700 rounded-lg shadow-lg">
                                        {monitorTypes.map((type) => (
                                            <button
                                                key={type}
                                                onClick={() => {
                                                    setFormData(prev => ({
                                                        ...prev,
                                                        monitor_type: type,
                                                        monitor_visible: type === "new" ? false : prev.monitor_visible
                                                    }));
                                                    setTypeDropdownOpen(false);
                                                }}
                                                className="w-full px-3 py-2 text-sm text-slate-300 hover:bg-slate-700 text-left transition-colors"
                                            >
                                                {type}
                                            </button>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* Public Dropdown */}
                        <div>
                            <label className="block text-sm font-medium text-slate-300 mb-1">
                                Monitor Visible
                            </label>
                            <div className="relative">
                                <button
                                    type="button"
                                    onClick={() => setVisibleDropdownOpen(!visibleDropdownOpen)}
                                    className="w-full flex items-center justify-between bg-slate-800 rounded-lg px-3 py-2 text-sm text-white border border-slate-700 hover:border-slate-600 transition-colors"
                                >
                                    <span>{formData.monitor_visible.toString()}</span>
                                    <ChevronDown size={16} className="text-slate-400" />
                                </button>

                                {visibleDropdownOpen && (
                                    <div className="absolute z-50 w-full mt-1 bg-slate-800 border border-slate-700 rounded-lg shadow-lg">
                                        {monitorVisble.map((visible) => (
                                            <button
                                                key={visible.toString()}
                                                onClick={() => {
                                                    setFormData(prev => ({ ...prev, monitor_visible: visible }));
                                                    setVisibleDropdownOpen(false);
                                                }}
                                                className="w-full px-3 py-2 text-sm text-slate-300 hover:bg-slate-700 text-left transition-colors"
                                            >
                                                {visible.toString()}
                                            </button>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* Product Dropdown */}
                        <div>
                            <label className="block text-sm font-medium text-slate-300 mb-1">
                                Product
                            </label>
                            <div className="relative">
                                <button
                                    type="button"
                                    onClick={() => setDropdownOpen(!dropdownOpen)}
                                    className="w-full flex items-center justify-between bg-slate-800 rounded-lg px-3 py-2 text-sm text-white border border-slate-700 hover:border-slate-600 transition-colors"
                                >
                                    <span>{products.find(p => p.id === formData.product_id)?.name || "Select product"}</span>
                                    <ChevronDown size={16} className="text-slate-400" />
                                </button>

                                {dropdownOpen && (
                                    <div className="absolute z-50 w-full mt-1 bg-slate-800 border border-slate-700 rounded-lg shadow-lg">
                                        {products.map((product) => (
                                            <button
                                                key={product.id}
                                                onClick={() => {
                                                    setFormData(prev => ({ ...prev, product_id: product.id }));
                                                    setDropdownOpen(false);
                                                }}
                                                className="w-full px-3 py-2 text-sm text-slate-300 hover:bg-slate-700 text-left transition-colors"
                                            >
                                                {product.name}
                                            </button>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* Example Inputs */}
                        <div>
                            <label className="block text-sm font-medium text-slate-300 mb-1">
                                Example URLs
                            </label>
                            <div className="space-y-2">
                                {formData.example_inputs.map((url, index) => (
                                    <div key={index} className="flex gap-2">
                                        <input
                                            type="text"
                                            value={url}
                                            onChange={(e) => updateInput('example_inputs', index, e.target.value)}
                                            className="flex-1 bg-slate-800 rounded-lg px-3 py-2 text-sm text-white border border-slate-700 focus:outline-none focus:ring-1 focus:ring-blue-500"
                                            placeholder="Enter URL"
                                        />
                                        <button
                                            onClick={() => removeInput('example_inputs', index)}
                                            className="p-2 hover:bg-slate-700 rounded-lg transition-colors"
                                        >
                                            <Trash2 size={16} className="text-slate-400" />
                                        </button>
                                    </div>
                                ))}
                                <button
                                    onClick={() => addInput('example_inputs')}
                                    className="flex items-center gap-2 px-3 py-2 text-sm text-slate-300 hover:text-white transition-colors"
                                >
                                    <Plus size={16} />
                                    Add URL
                                </button>
                            </div>
                        </div>

                        {/* Regions */}
                        <div>
                            <label className="block text-sm font-medium text-slate-300 mb-1">
                                Regions
                            </label>
                            <div className="space-y-2">
                                {formData.regions.map((region, index) => (
                                    <div key={index} className="flex gap-2">
                                        <input
                                            type="text"
                                            value={region}
                                            onChange={(e) => updateInput('regions', index, e.target.value)}
                                            className="flex-1 bg-slate-800 rounded-lg px-3 py-2 text-sm text-white border border-slate-700 focus:outline-none focus:ring-1 focus:ring-blue-500"
                                            placeholder="Enter region"
                                        />
                                        <button
                                            onClick={() => removeInput('regions', index)}
                                            className="p-2 hover:bg-slate-700 rounded-lg transition-colors"
                                        >
                                            <Trash2 size={16} className="text-slate-400" />
                                        </button>
                                    </div>
                                ))}
                                <button
                                    onClick={() => addInput('regions')}
                                    className="flex items-center gap-2 px-3 py-2 text-sm text-slate-300 hover:text-white transition-colors"
                                >
                                    <Plus size={16} />
                                    Add Region
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="mt-6 flex items-center justify-end gap-3">
                        <button
                            onClick={() => { setNewMonitorPopupOpen(false); handleBlur() }}
                            className="px-4 py-2 text-slate-300 hover:text-white text-sm transition-colors"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={handleSubmit}
                            className="flex items-center gap-2 px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white text-sm rounded-lg transition-colors"
                        >
                            <Save size={16} />
                            <span>Create new monitor</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>,
        document.body
    );
};

export default NewMonitorPopup;