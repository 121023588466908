import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../constants';
import axios from 'axios';
import { Loader2 } from 'lucide-react';

const SuccessPage: React.FC = () => {
    const navigate = useNavigate();
    const [attempts, setAttempts] = useState(0);
    const MAX_ATTEMPTS = 5;

    const checkPaymentStatus = async () => {
        try {
            const token = localStorage.getItem('token');

            const response = await axios.get(`${API_URL}/v1/customer/info`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                }
            });

            if (response.status === 200) {
                const { data } = response.data;

                if (data.paid) {
                    // navigate('/settings');
                    // window.location.reload()
                    window.location.href = '/settings';
                    return;
                }
            }

            if (attempts < MAX_ATTEMPTS) {
                setAttempts(prev => prev + 1);
                setTimeout(checkPaymentStatus, 1500);
            } else {
                navigate('/payment-error');
            }
        } catch (error) {
            console.error('Error checking payment status:', error);
        }
    };

    useEffect(() => {
        checkPaymentStatus();
    }, []);

    return (
        <div className="flex flex-col w-full min-h-screen bg-[#0f1729] items-center justify-center px-4">
            <div className="bg-[#1a2234] rounded-xl overflow-hidden shadow-xl max-w-md w-full p-8">
                <div className="flex flex-col items-center justify-center text-center space-y-6">
                    <div className="animate-spin">
                        <Loader2 className="h-12 w-12 text-blue-500" />
                    </div>

                    <div className="space-y-2">
                        <h1 className="text-2xl font-semibold text-slate-100">
                            Setting Up Your Dashboard
                        </h1>
                        <p className="text-slate-400">
                            Please wait while we prepare your dashboard...
                        </p>
                    </div>

                    <div className="text-sm text-slate-400">
                        This may take a few moments
                    </div>

                    {attempts > 2 && (
                        <div className="text-sm text-slate-400">
                            Taking longer than expected? Don't worry,
                            we're still processing your payment.
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SuccessPage;