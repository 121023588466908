import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { SettingsPage } from './pages/SettingsPage';
import { LoadingSpinner } from './components/common/LoadingSpinner';
import { useAuth } from './hooks/useAuth';
import Callback from './pages/CallbackPage';

import './styles/styles.css'
import { DashboardAccessPage } from './pages/DashboardAccessPage';
import { CustomersPage } from './pages/CustomersPage';

import { DashboardLayout } from './components/layout/DashboardLayout';
import { RedirectsPage } from './pages/monitors/RedirectsPage';
import { MonitorsPage } from './pages/monitors/MonitorTablePage';
import { MonitorDetailsPage } from './pages/monitors/MonitorDetailsPage';
import { LoadingProvider } from './contexts/LoadingContext';
import { PaymentPage } from './pages/PaymentPage';
import Setup from './components/Setup';
import NoPremiumPage from './components/Sidebar/NoPremium';
import { DISCORDOAUTH } from './constants';
import { CommandsPage } from './pages/CommandsPage';
import ProductCheckout from './components/Sidebar/Checkout/Checkout';
import SuccessPage from './pages/SuccessPage';
import PaymentErrorPage from './pages/PaymentErrorPage';




const App: React.FC = () => {
  const [isBlurred, setIsBlurred] = useState(false);


  const {
    isLoading: authLoading,
    isAuthorized,
    user,
    botData,
    customerData,
    handleLogout,
    developer,
    paid,
    hasCustomerProfile,
    profilePending,
    setHasCustomerProfile,
    gotData
  } = useAuth();


  const handleBlur = () => {
    setIsBlurred(!isBlurred);
  };



  // loading spinner
  if (authLoading) {
    return <LoadingSpinner />;
  }

  // discord auth handling
  if (window.location.pathname === '/callback') {
    return (
      <Router>
        <Routes>
          <Route path="/callback" element={<Callback />} />
        </Routes>
      </Router>
    );
  }
  if (window.location.pathname === '/payment-error') {
    return (
      <Router>
        <Routes>
          <Route path="/callback" element={<PaymentErrorPage />} />
        </Routes>
      </Router>
    );
  }

  // user has bought access but not yet created --> Start Setup
  console.log(paid)
  console.log(hasCustomerProfile)
  console.log(profilePending)
  if (paid && !hasCustomerProfile && !profilePending) {
    return (
      <Setup handleLogOut={handleLogout} />
    )
  }

  if (window.location.pathname === '/success') {
    return (
      <Router>
        <Routes>
          <Route path="/success" element={<SuccessPage />} />
        </Routes>
      </Router>
    );
  }

  // user not bought access
  else if (!paid && !hasCustomerProfile && gotData) {
    console.log(paid)
    console.log(hasCustomerProfile)

    return (
      <LoadingProvider>
        <ProductCheckout />
      </LoadingProvider>
    )
  }

  // login if error
  if (!user || !botData || !customerData) {
    console.log(user)
    console.log(botData)
    console.log(customerData)
    window.location.href = DISCORDOAUTH;
    return null;
  }

  return (
    <LoadingProvider>
      <Router>
        <DashboardLayout
          user={user}
          botData={botData}
          customerData={customerData}
          onLogout={handleLogout}
          isBlurred={isBlurred}
          handleBlur={handleBlur}
          developer={developer} >
          <Routes>
            <Route path="/commands" element={<CommandsPage botData={botData} customerData={customerData} handleLogOut={handleLogout} handleBlur={handleBlur} />} />
            <Route path="/settings" element={<SettingsPage botData={botData} customerData={customerData} handleLogout={handleLogout} handleBlur={handleBlur} bot_pending={profilePending} />} />
            <Route path="/dashboard-access" element={<DashboardAccessPage botData={botData} customerData={customerData} handleLogout={handleLogout} handleBlur={handleBlur} />} />
            <Route path="/customers" element={<CustomersPage botData={botData} customerData={customerData} />} />
            <Route path="/monitors/redirects" element={<RedirectsPage botData={botData} customerData={customerData} handleLogout={handleLogout} handleBlur={handleBlur} />} />
            <Route path="/monitors" element={<MonitorsPage botData={botData} customerData={customerData} handleLogout={handleLogout} handleBlur={handleBlur} developer={developer} />} />
            <Route path="/" element={<Navigate to="/settings" replace />} />
            <Route
              path="/monitors/:uuid"
              element={
                <MonitorDetailsPage
                  botData={botData}
                  customerData={customerData}
                  handleLogout={handleLogout}
                  developer={developer} handleBlur={handleBlur} />
              }
            />
            <Route path="/payment" element={<PaymentPage botData={botData} customerData={customerData} />} />
            {/*
          <Route path="/updates" element={<Updates />} />
          
          <Route path="/ticket/settings" element={<TicketSettings />} />
          <Route path="/ticket/logs" element={<TicketLogs />} />

           */}
          </Routes>
        </DashboardLayout>
      </Router>
    </LoadingProvider>
  );
};


export default App;
